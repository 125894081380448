import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-pageing',
  templateUrl: './pageing.component.html',
  styleUrls: ['./pageing.component.scss'],
})
export class PageingComponent implements OnInit {
  @Input() limit = 0;
  @Input() offset = 0;
  @Input() totalCount = 0;
  @Output() pageEmit = new EventEmitter<any>();
  constructor(private activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {}

  public count() {
    // console.log(Array.from(Array(Math.floor(this.totalCount / 12) + 1).keys()));
    return Array.from(Array(Math.floor(this.totalCount / 12) + 1).keys());
  }

  public nowPage() {
    const num = this.activatedRoute.snapshot.queryParams.offset;
    return num ? Number(num) : 0;
  }

  public onPageing(num: any) {
    this.pageEmit.emit(num);
  }
}
