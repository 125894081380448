<div class="l-wrapper">
  <app-header></app-header>
  <div class="l-voice">
    <div class="l-voice__title">
      <app-title label="Customer's Voice" title="お客様の声"></app-title>
    </div>
    <div class="l-voice__main">
      <app-customers-voice-view></app-customers-voice-view>
    </div>
  </div>
  <app-footer></app-footer>
</div>
