<div class="l-wrapper">
  <app-header></app-header>
  <div class="l-top">
    <div class="l-top__first-view">
      <img
        src="./../../../../assets/img/new/top-first-view.png"
        alt="ダイナモBスタジオで|健康な体へ|体育が楽しくなる"
        class="c-pc"
      />
      <img
        src="./../../../../assets/img/new/top-first-view-sp.png"
        alt="ダイナモBスタジオで|健康な体へ|体育が楽しくなる"
        class="c-sp"
      />
    </div>
    <div class="l-top__title">
      <!-- <app-title label="Customer's Voice" title="お客様の声"></app-title> -->
    </div>
    <div class="l-top__news">
      <h3 class="l-top__news__title">お知らせ</h3>
      <div class="l-top__news__list" *ngIf="list">
        <ng-container *ngFor="let item of list.contents">
          <a [href]="'/blog-content/' + item.id">
            <p>{{ formatDate(item.publishedAt) }}</p>
            <p>{{ item.title }}</p>
          </a>
        </ng-container>
      </div>
    </div>
    <div class="l-top__news">
      <h3 class="l-top__news__title">ブログ</h3>
      <div class="l-top__news__list" *ngIf="blogList">
        <ng-container *ngFor="let item of blogList.contents">
          <a [href]="'/blog-content/' + item.id">
            <p>{{ formatDate(item.publishedAt) }}</p>
            <p>
              {{ item.title }}
            </p>
          </a>
        </ng-container>
      </div>
    </div>
    <div class="l-top__description">
      <div class="l-top__description__under">
        <h2 class="l-top__description__under__title">ダイナモBスタジオとは</h2>
        <span class="l-top__description__under__border"></span>
      </div>
      <span class="l-top__description__text">
        「ダイナモ」という英語は「発電機」を意味します。これを我々のサービスの精神に見<br />立て、ユーザーが体を動かしてエネルギーを得ることを目指しています。
      </span>
      <span class="l-top__description__text">
        さらに、Bは以下の複数の要素を象徴しています。<br />
        これらの価値観が我々のサービスの根幹をなしています。
      </span>

      <div class="l-top__description__list">
        <span>Body</span>
        <span>体の動かし方に意識を向けます。</span>

        <span>Balance</span>
        <span>バランスの取れた生活を推奨します。</span>

        <span>Brain</span>
        <span>思考力の育成を促します。</span>

        <span>Best</span>
        <span>常に最善を目指します。 </span>
      </div>
    </div>

    <div class="l-top__content">
      <app-top-content-kids></app-top-content-kids>
    </div>
    <div class="l-top__content">
      <app-top-content-adult></app-top-content-adult>
    </div>

    <div class="l-top__campaign">
      <app-campaign-view [isPlan]="true"></app-campaign-view>
    </div>

    <div class="l-top__contact-link">
      <app-contact-link></app-contact-link>
    </div>
  </div>
  <app-footer></app-footer>
</div>
