<div class="l-top-link">
  <div class="l-top-link__left" [style.background]="backgroundcolor">
    <a
      [href]="link"
      class="l-top-link__left__link"
      [style.color]="backgroundcolor"
    >
      <h3>{{ title }}</h3>
      <img [src]="'./../../../../assets/img/new/vector-' + type + '.png'" />
    </a>

    <ul class="l-top-link__left__list">
      <li *ngFor="let item of list">{{ item }}</li>
    </ul>
  </div>
  <div class="l-top-link__right">
    <img [src]="img" class="c-pc" />
    <img [src]="imgSp" class="c-sp" />
  </div>
</div>
