import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-voice',
  templateUrl: './voice.component.html',
  styleUrls: ['./voice.component.scss'],
})
export class VoiceComponent implements OnInit {
  constructor(private meta: Meta, private title: Title) {}

  ngOnInit(): void {
    this.meta.addTag({
      name: 'description',
      content:
        '運動不足から節々が痛かったり、太ってきたと感じて来た時に、ダイナモBスタジオを発見しました。トレーニングを重ねる事で運動不足が解消されていき、体力が向上してきたのを感じております。体力面だけではなく、ウエストが4センチも落ちてきたので、続てけて来て良かったと感じてます。今後もトレーニングを続けていこうと思います。',
    });
    this.title.setTitle('お客様の声 | ダイナモBスタジオ');
  }
}
