import { Component, EventEmitter, Output, OnInit } from '@angular/core';
import { HttpService } from '../../base/http.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
})
export class LoginFormComponent implements OnInit {
  public formGroup: FormGroup;
  @Output() onLoading = new EventEmitter<any>();
  @Output() onError = new EventEmitter<any>();

  constructor(private httpService: HttpService, private router: Router) {
    this.createFormGroup();
  }

  public createFormGroup() {
    this.formGroup = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required]),
    });
  }

  ngOnInit(): void {}

  // tslint:disable-next-line: typedef
  public onAccept() {
    if (this.formGroup.valid) {
      this.httpService.post('local/login', this.formGroup.value).subscribe(
        (value) => {
          if (value.stuats === 200) {
            this.router.navigate(['/mypage'], {
              queryParams: {
                title: '',
                messege: '',
              },
            });
          } else {
            this.onError.emit(value);
          }
        },
        (error) => this.onError.emit(error),
        () => console.log('onCompleted')
      );
    }
  }
}
