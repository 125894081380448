import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-preschooler',
  templateUrl: './preschooler.component.html',
  styleUrls: ['./preschooler.component.scss'],
})
export class PreschoolerComponent implements OnInit {
  constructor(private meta: Meta, private title: Title) {}
  public questionnaires: any = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];

  ngOnInit(): void {
    this.meta.addTag({
      name: 'description',
      content:
        '未就学児向けのかけっこ・体幹教室へようこそ。当教室では、「投げる」「取る」「飛ぶ」「走る」といった基本的な運動スキルを育成します。さらに、かけっこで速く走るコツや転ばないためのテクニックも指導します。まずは、体験入店にお越しいただき、私たちの楽しく効果的なトレーニングを体験してください。',
    });
    this.title.setTitle('未就学児体験入店 | ダイナモBスタジオ');
  }
}
