import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-price-plan',
  templateUrl: './price-plan.component.html',
  styleUrls: ['./price-plan.component.scss'],
})
export class PricePlanComponent implements OnInit {
  public number = 1;
  ngOnInit(): void {}

  onNumber(number: number) {
    this.number = number;
  }
}
