<div class="l-wrapper">
  <app-header></app-header>
  <app-loading *ngIf="isLoading"></app-loading>

  <div class="l-main">
    <div class="l-content-no-user">
      <div class="l-form-wrapper">
        <div class="l-form-wrapper__title">
          <app-title
            class="u-mgn--t24px l-login"
            label="会員ログイン"
          ></app-title>
        </div>
        <div class="l-login-error" *ngIf="isLoginError">
          <div class="l-login-error-title">
            <img src="/assets/img/checkbox.png" alt="" />
            メールアドレスもしくはパスワードが不正です。
          </div>
          <div class="c-login-error-label">
            お手数ですが再度ご入力をお願いします。
          </div>
        </div>
        <app-login-form
          (onLoading)="onLoading(onLoading)"
          (onError)="onError(error)"
        ></app-login-form>
      </div>
    </div>
  </div>
  <app-footer></app-footer>
</div>
