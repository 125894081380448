import { Component, OnInit, Injectable, Inject } from '@angular/core';
import { DomSanitizer, Meta, Title } from '@angular/platform-browser';
import {
  ParamMap,
  Event,
  NavigationEnd,
  RouterEvent,
  Router,
  ActivatedRoute,
} from '@angular/router';
import { combineLatest, from } from 'rxjs';
const moment = require('moment');
import { environment, client } from './../../../../environments/environment';
import { DOCUMENT } from '@angular/common';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-blog-content',
  templateUrl: './blog-content.component.html',
  styleUrls: ['./blog-content.component.scss'],
})
export class BlogContentComponent implements OnInit {
  constructor(
    private meta: Meta,

    private title: Title,
    private activatedRoute: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private router: Router,
    @Inject(DOCUMENT) private dom: any
  ) {}

  public blog = {
    publishedAt: '',
    title: '',
    content: '',
    eyecatch: { url: '' },
  };

  public categoryList: {
    contents: [
      {
        name: '';
        id: '';
      }
    ];
  };
  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((params: ParamMap) => {
      const id = params.get('id');
      combineLatest(this.getCategoryList(), this.blogContent(id)).subscribe(
        ([category, content]) => {}
      );
    });
    this.router.events
      .pipe(filter((e: Event): e is any => e instanceof NavigationEnd))
      .subscribe((e: any) => {
        // this.createCanonicalUrl(`${environment.baseURL}${e.url}`);
        this.setCanonicalURL(`${environment.baseURL}${e.url}`);
      });
  }
  setCanonicalURL(url: string = null): void {
    if (url) {
      this.meta.updateTag({ rel: 'canonical', href: url });
    } else {
      console.log('Canonical URL is required');
    }
  }
  public format(date: string, formatValue: string): any {
    return moment(date).format(formatValue);
  }

  public formatDate(date: string): any {
    return this.format(date, 'YYYY年MM月DD日');
  }

  public blogContent(id: string) {
    return from(
      client
        .get({
          endpoint: 'blogs',
          contentId: id,
        })
        .then((res) => {
          res.content = this.sanitizer.bypassSecurityTrustHtml(res.content);
          this.blog = res;
          console.log(res.title);
          this.title.setTitle(res.title + ' | ダイナモBスタジオ');
          this.meta.addTag({
            name: 'description',
            content: res.content.changingThisBreaksApplicationSecurity.replace(
              /<("[^"]*"|'[^']*'|[^'">])*>|\n/g,
              ''
            ),
          });
        })
    );
  }

  public getCategoryList() {
    return from(
      client
        .get({
          endpoint: 'categories',
          queries: { orders: '-publishedAt', limit: 100, offset: 0 },
        })
        .then((data) => {
          console.log(data);
          this.categoryList = data;
        })
    );
  }
}
