import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-question-view',
  templateUrl: './question-view.component.html',
  styleUrls: ['./question-view.component.scss'],
})
export class QuestionViewComponent implements OnInit {
  constructor() {}
  public isSeeMore = false;

  ngOnInit(): void {}

  seeMore() {
    this.isSeeMore = true;
  }
}
