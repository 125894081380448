<app-tab-layout (num)="onNumber($event)">
  <div class="l-customers-voice">
    <div class="l-customers-voice__main" *ngIf="number !== 2">
      <div class="l-customers-voice__main__header">
        <img src="./../../../../assets/img/new/worman-icon.png" />
        <div class="l-customers-voice__main__header__title">
          <h3 class="l-customers-voice__main__header__title__text">
            運動を楽しく続けられます。
          </h3>
          <p class="l-customers-voice__main__header__title__status">
            30代　女性
          </p>
        </div>
      </div>
      <div class="l-customers-voice__main__description">
        <p>
          運動不足から節々が痛かったり、太ってきたと感じて来た時に、ダイナモBスタジオを発見しました。
          トレーニングを重ねる事で運動不足が解消されていき、体力が向上してきたのを感じております。
          体力面だけではなく、ウエストが4センチも落ちてきたので、続てけて来て良かったと感じてます。
          今後もトレーニングを続けていこうと思います。
        </p>
      </div>
    </div>
    <div class="l-customers-voice__main" *ngIf="number !== 2">
      <div class="l-customers-voice__main__header">
        <img src="./../../../../assets/img/new/worman-icon.png" />
        <div class="l-customers-voice__main__header__title">
          <h3 class="l-customers-voice__main__header__title__text">
            様々なトレーニングができます。
          </h3>
          <p class="l-customers-voice__main__header__title__status">
            60代　女性
          </p>
        </div>
      </div>
      <div class="l-customers-voice__main__description">
        <p>
          運動教室では普通のトレーニングと違い、様々な
          トレーニングをさせて頂けます。
          動かしなれない体幹を鍛える事で色んな動きが楽に
          なってきた実感があります。例えば、物を持つのが
          楽になったり、腕を後ろに回したり子供を抱っこするのが
          苦ではなくなったので、続けてきてよかったと 実感しております。
        </p>
      </div>
    </div>
    <div class="l-customers-voice__main" *ngIf="number !== 2">
      <div class="l-customers-voice__main__header">
        <img src="./../../../../assets/img/new/worman-icon.png" />
        <div class="l-customers-voice__main__header__title">
          <h3 class="l-customers-voice__main__header__title__text">
            無理なく楽しく続けられます。
          </h3>
          <p class="l-customers-voice__main__header__title__status">
            40代　女性
          </p>
        </div>
      </div>
      <div class="l-customers-voice__main__description">
        <p>
          トレーニングだけは無く、人と話すことも出来る
          ので、楽しく続けることが出来ます。
          トレーニングを続けるために様々な工夫をして
          頂けているので、通いやすく楽しく続けていけます。
          その上で身体・運動能力を数値として残してもらえる
          ので、目標設定もできトレーニングを続けています。
        </p>
      </div>
    </div>
    <div class="l-customers-voice__main" *ngIf="number !== 1">
      <div class="l-customers-voice__main__header">
        <img src="./../../../../assets/img/new/kids-mens-icon.png" />
        <div class="l-customers-voice__main__header__title">
          <h3 class="l-customers-voice__main__header__title__text">
            【体験入店】の保護者様のアンケート
          </h3>
          <p class="l-customers-voice__main__header__title__status">
            小学3年生 男の子
          </p>
        </div>
      </div>
      <div class="l-customers-voice__main__description">
        <p>
          子供が楽しく運動を行い、運動能力が向上するイメージがつかめました。
        </p>
      </div>
    </div>
    <div class="l-customers-voice__main" *ngIf="number !== 1">
      <div class="l-customers-voice__main__header">
        <img src="./../../../../assets/img/new/kids-mens-icon.png" />
        <div class="l-customers-voice__main__header__title">
          <h3 class="l-customers-voice__main__header__title__text">
            【体験入店】の保護者様のアンケート
          </h3>
          <p class="l-customers-voice__main__header__title__status">
            小学3年生 男の子
          </p>
        </div>
      </div>
      <div class="l-customers-voice__main__description">
        <p>身体の使い方を教えて頂き、とても良い指導と感じました。</p>
      </div>
    </div>

    <div class="l-customers-voice__main" *ngIf="number !== 1">
      <div class="l-customers-voice__main__header">
        <img src="./../../../../assets/img/new/kids-worman-icon.png" />
        <div class="l-customers-voice__main__header__title">
          <h3 class="l-customers-voice__main__header__title__text">
            【体験入店】の保護者様のアンケート
          </h3>
          <p class="l-customers-voice__main__header__title__status">
            小学5年生 女の子
          </p>
        </div>
      </div>
      <div class="l-customers-voice__main__description">
        <p>
          子供が集中して楽しんでました。指導も細かくして頂き、良いと感じております。
        </p>
      </div>
    </div>

    <div class="l-customers-voice__main" *ngIf="number !== 1">
      <div class="l-customers-voice__main__header">
        <img src="./../../../../assets/img/new/kids-worman-icon.png" />
        <div class="l-customers-voice__main__header__title">
          <h3 class="l-customers-voice__main__header__title__text">
            【体験入店】の保護者様のアンケート
          </h3>
          <p class="l-customers-voice__main__header__title__status">
            小学1年生 女の子
          </p>
        </div>
      </div>
      <div class="l-customers-voice__main__description">
        <p>
          運動会の前だったので、走り方が理解できとても楽しかったです。自身にも活かせそうな内容も教えていただけたので、大変満足です。
        </p>
      </div>
    </div>
  </div>
</app-tab-layout>
