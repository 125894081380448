import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Meta, Title } from '@angular/platform-browser';
import { SnackbarService } from '../../atom/snackbar/snackbar.service';
import { PersonalConsentFormService } from './personal-consent-form.service';
import { HttpService } from '../../base/http.service';
import { Router } from '@angular/router';
import { of } from 'rxjs';
import { mergeMap, take } from 'rxjs/operators';
import { SelectArrayPrefectures } from '../../base/prefectures';

@Component({
  selector: 'app-personal-consent-form',
  templateUrl: './personal-consent-form.component.html',
  styleUrls: ['./personal-consent-form.component.scss'],
})
export class PersonalConsentFormComponent implements OnInit {
  constructor(
    private httpService: HttpService,
    private router: Router,
    private service: PersonalConsentFormService,
    public snackbarService: SnackbarService,
    private meta: Meta,
    private title: Title
  ) {}
  public formGroup: UntypedFormGroup = this.service.createFormGroup();
  public isLoading = false;
  public prefectures: string[] = SelectArrayPrefectures;
  public staffOption = [`松本 央真`];

  ngOnInit(): void {}

  public onLoading(isLoading: any) {
    this.isLoading = isLoading;
  }

  onChangeNumber() {
    this.service
      .zipcode(this.formGroup.value.postcode)
      .pipe(
        take(1),
        mergeMap((value: any) => {
          this.formGroup.patchValue({
            address: value.messege.address1,
            address1: value.messege.address2 + value.messege.address3,
          });
          return of(value);
        })
      )
      .subscribe(
        (value: any) => console.log(value),
        (error: any) => console.log(error),
        () => console.log('onCompleted')
      );
  }

  public onAccept() {
    if (this.formGroup.valid) {
      this.httpService.post('consent-contact', this.formGroup.value).subscribe(
        (value) => {
          if (value.stuats === 200) {
            this.router.navigate(['/complete'], {
              queryParams: {
                iconSrc: '/assets/img/vector.png',
                title: 'ご入会ありがとうございました。',
                messege:
                  'ご入会いただき、ありがとうございます。<br>入力していただきました内容についてはメールに届いております。<br>一度ご確認のほどお願い致します。',
              },
            });
          } else {
            // this.onError.emit(value);
          }
        },
        (error) => console.log(error),
        () => console.log('onCompleted')
      );
    } else {
      this.snackbarService.pushValidationMessage();
    }
  }
}
