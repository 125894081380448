<div class="l-wrapper">
  <app-header></app-header>
  <div class="l-top-kids">
    <div class="l-top-kids__first-view">
      <img
        src="./../../../../assets/img/new/kids-first-view.png"
        alt="いつまでも元気な体に|体を動かすって楽しい|体を動かすのが辛い"
        class="c-pc"
      />
      <img
        src="./../../../../assets/img/new/kids-first-view-sp.png"
        alt="いつまでも元気な体に|体を動かすって楽しい|体を動かすのが辛い"
        class="c-sp"
      />
    </div>
    <div class="l-top-kids__second">
      <span class="l-top-kids__second__title">
        <p>ダイナモBスタジオは、</p>
        <p>運動の楽しさや基礎を教える運動教室です。</p>
      </span>

      <div class="l-top-kids__second__description">
        <p>
          当店が目指していることは、今以上にお子様が「運動ができる」「運動を楽しめる」
        </p>

        <p>ようになることを何よりも大切に考えております。</p>
        <p>学校体育では30人近くの子供を教師1人で見ております。</p>
        <p>正直、1人で見る人数としてはキャパオーバーだと感じます。</p>
        <p>そのため、当店では運動を教えるスタッフは</p>
        <p>1指導者に対して最大10人以下の教室運営を行っております。</p>
      </div>
    </div>

    <app-kids-service></app-kids-service>

    <div class="l-top-kids__fastidiousness">
      <h3 class="l-top-kids__fastidiousness__title">
        <img src="./../../../../assets/img/new/4.png" alt="4" />つのこだわり
      </h3>
      <div class="l-top-kids__fastidiousness__description">
        <img
          src="./../../../../assets/img/new/fastidiousness-1.png"
          alt="指導方法|「褒める」「楽しく」「考える」を
          ダイナモBスタジオでは重要視してい
          ます。一番重要なのはお子様が「また来た
          い」と思えることです。お子様が主体的に
          活動できるよう、やる気を引き出す指導を
          何よりも大切にしています。
        "
        />
        <img
          src="./../../../../assets/img/new/fastidiousness-2.png"
          alt="安全性|けが防止のため人工芝を下に敷いており、
          怪我防止に注力しております。
          また、トレーニングの前には準備運動を行
          い、体に負担がかからないようにしており
          ます。
          "
        />
        <img
          src="./../../../../assets/img/new/fastidiousness-3.png"
          alt="結果を出す|ダイナモＢスタジオでは、オリジナル体力
          測定シートを作成しており、測定結果を基
          にしたトレーニングを実施しています。
          また、測定方法の見直しや指導方法改善の
          ため知識や技術のアップデートを常時行
          い、運動能力向上の結果に繋げておりま
          す。"
        />
        <img
          src="./../../../../assets/img/new/fastidiousness-4.png"
          alt="協調性の向上|運動を通じて、コミュニケーション能力を
          養っていくことも目的としています。
          活動の中で、子供同士でコミュニケーショ
          ンを取る機会もあるため、自然と協調性が
          身に着きます。
          "
        />
      </div>
    </div>

    <div class="l-top-kids__programme">
      <h3 class="l-top-kids__programme__title">プログラム作成者</h3>
      <div class="l-top-kids__programme__description">
        <div class="l-top-kids__programme__description__profile">
          <img
            src="./../../../../assets/img/new/maeda-taketo.png"
            alt="前田 岳人"
          />
          <div class="l-top-kids__programme__description__profile__name">
            <span>まえだ たけと</span>
            <a href="https://twitter.com/maetake88" target="_blank">
              <img
                src="./../../../../assets/img/new/twitter_blue.png"
                alt="前田 岳人のツイッター"
              />
            </a>
            <a href="https://www.instagram.com/maetake0530/" target="_blank">
              <img
                src="./../../../../assets/img/new/instagram_blue.png"
                alt="前田 岳人のインスタグラム"
              />
            </a>
          </div>
          <ul>
            <li>社会人アスリートを多数指導</li>
            <li>契約選手多数</li>
            <li>県立高校陸上競技部外部コーチ</li>
            <li>フィットネスクラブチーフトレーナー経験あり</li>
          </ul>
        </div>
        <div class="l-top-kids__programme__description__career">
          <span class="l-top-kids__programme__description__career__title">
            <p class="l-top-kids__programme__description__career__title__japan">
              キャリア
            </p>
            <p class="l-top-kids__programme__description__career__title__en">
              Career
            </p>
          </span>

          <img src="./../../../../assets/img/new/kids-line.png" alt="" />
          <ul class="l-top-kids__programme__description__career__list">
            <li>大阪堀江のパーソナルトレーニングジム「W-GYM」代表</li>
            <li>一般社団法人 五感能力覚醒機構 CHEETAH.AC特別アドバイザー</li>
            <li>陸上クラブチーム外部アドバイザー</li>
            <li>医師と提携した運動指導者向けオンラインサロン主宰</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="l-top-kids__achievement">
      <h3 class="l-top-kids__achievement__title">トレーニング効果実例</h3>
      <div class="l-top-kids__achievement__description">
        <div class="l-top-kids__achievement__description__under">
          <h2 class="l-top-kids__achievement__description__under__title">
            弊社代表が実際にプログラムを体験してみました！
          </h2>
          <span
            class="l-top-kids__achievement__description__under__border"
          ></span>
        </div>

        <img
          src="./../../../../assets/img/new/achievment-pc.png"
          alt="プログラム体験前　プログラム体験後 運動実績"
          class="c-pc"
        />
        <div class="l-top-kids__achievement__description__img c-sp">
          <img
            src="./../../../../assets/img/new/achievment-sp1.png"
            alt="プログラム体験前　プログラム体験後 運動実績"
          />
          <img
            width="24px"
            height="92"
            src="./../../../../assets/img/new/achievment-sp2.png"
            alt="矢印"
          />
          <img
            src="./../../../../assets/img/new/achievment-sp3.png"
            alt="プログラム体験後"
          />
        </div>
        <ul class="l-top-kids__achievement__description__list">
          <li>
            長距離でも、1キロ当たりの速度が30秒近くも早くなっている事が分かります。
          </li>
          <li>子供の場合はこれ以上に効果が出る事が予想できます。</li>
        </ul>
      </div>
    </div>
    <div class="l-top-kids__flow">
      <div class="l-top-kids__flow__content1">
        <h2 class="l-top-kids__flow__content1__title">施設利用の流れ</h2>
      </div>
      <div class="l-top-kids__flow__content2">
        <img
          src="./../../../../assets/img/new/kids-ninthview1.png"
          alt="電話、Web、LINEにて連絡があり次第、予約を取らせて頂きます。"
        />
        <img
          src="./../../../../assets/img/new/kids-top-ninthview-right.png"
          class="--right c-pc"
        />
        <img
          src="./../../../../assets/img/new/kids-top-ninthview-bottom.png"
          class="--right c-sp"
        />
        <img
          src="./../../../../assets/img/new/kids-ninthview2.png"
          alt="簡単な質問用紙に記入をお願いしております。運動できる服装と水分の用意をお願い致します。"
        />
        <img
          src="./../../../../assets/img/new/kids-top-ninthview-right.png"
          class="--right c-pc"
        />
        <img
          src="./../../../../assets/img/new/kids-top-ninthview-bottom.png"
          class="--right c-sp"
        />
        <img
          src="./../../../../assets/img/new/kids-ninthview3.png"
          alt="約60分ほどの運動を体験して頂きます。どのような運動の効果があるかスタッフが保護者の方に説明もさせて頂きます。"
        />
        <img
          src="./../../../../assets/img/new/kids-ninthview4.png"
          alt="スタッフが全員にお声掛けさせていただきます。入会希望の方はこちらで入会手続きをさせて頂きます。"
        />
        <img
          src="./../../../../assets/img/new/kids-top-ninthview-right.png"
          class="--right c-pc"
        />
        <img
          src="./../../../../assets/img/new/kids-top-ninthview-bottom.png"
          class="--right c-sp"
        />
        <img
          src="./../../../../assets/img/new/kids-ninthview5.png"
          alt="簡単なアンケートを記入して頂き終了となります。"
        />
      </div>
    </div>

    <div class="l-top-kids__campaign">
      <app-campaign-view [isPlan]="false"></app-campaign-view>
    </div>

    <div class="l-top-kids__contact-link">
      <app-contact-link></app-contact-link>
    </div>

    <app-footer></app-footer>
  </div>
</div>
