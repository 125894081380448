import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  Optional,
  Self,
  ViewChild,
} from '@angular/core';
import { AbstractControl, NgControl } from '@angular/forms';
// import { ValidationMessageService } from 'src/app/service/validation-message.service';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
})
export class InputComponent implements OnInit {
  @Input() type = 'text';
  @Input() label = '';
  @Input() placeholder = '';
  @Input() list = '';
  @Input() unit = '';
  // @Input() status = EFormStatus.EDITABLE;
  @Input() isError = false;
  @Input() require = false;
  @Input() maxValue = 0;
  @Input() helpText = '';

  @ViewChild('input') element: ElementRef;

  required = false;

  onChange: (value: string | number) => void;
  onTouched: () => void;

  constructor(
    @Self() @Optional() public control: NgControl,
    // public validationMessageService: ValidationMessageService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    if (this.control) {
      this.control.valueAccessor = this;
    }
  }

  ngOnInit(): void {
    const validator = this.control?.control.validator;
    this.required = validator
      ? validator({} as AbstractControl)?.required || false
      : false;
  }

  // get isReadOnly() {
  //   return this.status === EFormStatus.READONLY;
  // }

  // get isDisabled() {
  //   return this.status === EFormStatus.DISABLED;
  // }

  getReadOnlyLabel(value: string | number) {
    return value;
  }

  // ControlValueAccessor
  writeValue(value: string | number) {
    if (this.element) {
      this.element.nativeElement.value = value;
      this.changeDetectorRef.detectChanges();
    }
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }
}
