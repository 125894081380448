import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-semi-personal',
  templateUrl: './semi-personal.component.html',
  styleUrls: ['./semi-personal.component.scss'],
})
export class SemiPersonalComponent implements OnInit {
  constructor(private meta: Meta, private title: Title) {}

  ngOnInit(): void {
    this.meta.addTag({
      name: 'description',
      content:
        '西宮市で展開しているセミパーソナルトレーニングでは、足腰を中心としたエクササイズによって、ダイエット効果を促進し、健康的な身体作りを実現しています。',
    });
    this.title.setTitle('セミパーソナル | ダイナモBスタジオ');
  }
}
