<app-top-content-link
  link="/semi-personal"
  title="セミパーソナル"
  type="green"
  backgroundcolor="#19A78E"
  [list]="list"
  img="./../../../../assets/img/new/top-content-adult.png"
  imgSp="./../../../../assets/img/new/top-content-adult-sp.png"
>
</app-top-content-link>
