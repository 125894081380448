<div class="l-wrapper">
  <app-header></app-header>
  <div class="l-about">
    <img src="/assets/img/title-about.png" alt="会社概要" class="c-pc" />
    <img src="/assets/img/title-about-sp.png" alt="会社概要" class="c-sp" />
    <div class="l-about__company">
      <span>名称</span>
      <span>株式会社growth develop</span>

      <span>設立</span>
      <span>2022年10月</span>

      <span>資本金</span>
      <span>3,000,000円</span>

      <span>代表者</span>
      <span>松本　央真</span>

      <span>本社所在地</span>
      <span
        >〒546-0044<br class="c-sp" />大阪府大阪市東住吉区北田辺2-3-9 1 F</span
      >

      <span>電話番号</span>
      <span>0798-61-5344</span>

      <span>事業内容</span>
      <span
        >運動教育事業<br class="c-sp" />
        <p class="c-pc">|</p>
        システム開発事業</span
      >
    </div>
  </div>
  <app-footer></app-footer>
</div>
