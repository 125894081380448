<div class="l-question">
  <app-title label="FAQ" title="よくある質問"></app-title>
  <div class="l-question__content2">
    <div class="l-question__content2__question">
      <img src="./../../../../assets/img/question-icon.png" />
      <p>いつやってますか？</p>
      <img src="./../../../../assets/img/anser-icon.png" />
      <p>
        スケジュールに基づいた形となっております。<br />
        <span>スケジュールページは<a href="/schedule">こちら</a></span>
      </p>
    </div>
    <div class="l-question__content2__question">
      <img src="./../../../../assets/img/question-icon.png" />
      <p>他の運動施設とは何が違いますか？</p>
      <img src="./../../../../assets/img/anser-icon.png" />
      <p>
        当施設は大人と子供で差がございます。
        当施設は運動だけではなく、来て頂いた方全員でコミュニケーションを取れるようなプログラムも用意しております。
      </p>
    </div>
    <div class="l-question__content2__question">
      <img src="./../../../../assets/img/question-icon.png" />
      <p>普段、運動をしませんが大丈夫でしょうか？</p>
      <img src="./../../../../assets/img/anser-icon.png" />
      <p>
        はい、運動をしていない人でも運動を続けていけるような仕組みを提供しております。
      </p>
    </div>
    <div class="l-question__content2__question">
      <img src="./../../../../assets/img/question-icon.png" />
      <p>予約はした方がいいですか？</p>
      <img src="./../../../../assets/img/anser-icon.png" />
      <p>
        体験入店は予約をお願いしております。予約はLineでお願いしております。
      </p>
    </div>
    <div class="l-question__content2__question">
      <img src="./../../../../assets/img/question-icon.png" />
      <p>月謝の料金はどのくらいになりますか？</p>
      <img src="./../../../../assets/img/anser-icon.png" />
      <p>
        子供は週1回 月額7,700円（税込み）<br />
        ※11月まで入会された方のみとなっております。<br />
        大人は月額7,500円（税込み）<br />
        上記両方とも体験料金は無料となっております。<br />
        詳細は以下の料金プランページをご確認ください。<br />
        <span>料金プランページは<a href="/price">こちら</a></span>
      </p>
    </div>
    <ng-container *ngIf="isSeeMore">
      <div class="l-question__content2__question">
        <img src="./../../../../assets/img/question-icon.png" />
        <p>入金方法は何ができますか？</p>
        <img src="./../../../../assets/img/anser-icon.png" />
        <p>クレジットカードをお願いしております。</p>
      </div>
      <div class="l-question__content2__question">
        <img src="./../../../../assets/img/question-icon.png" />
        <p>ダイナモBスタジオでは具体的にどんなことをしますか？</p>
        <img src="./../../../../assets/img/anser-icon.png" />
        <p>
          運動者がTRXやアニマルフローや体幹トレーニングをメンバーのペースに合わせてメニューを組んでおります。<br />
          子供向けに関しては、準備運動・体幹マット運動、TRXなど・走る練習の3項目で実施しております。
        </p>
      </div>
      <div class="l-question__content2__question">
        <img src="./../../../../assets/img/question-icon.png" />
        <p>身体測定、体力測定はいつ行いますか？</p>
        <img src="./../../../../assets/img/anser-icon.png" />
        <p>3カ月に1度のみとさせて頂いております。</p>
      </div>
      <div class="l-question__content2__question">
        <img src="./../../../../assets/img/question-icon.png" />
        <p>持ち物は何が必要ですか？</p>
        <img src="./../../../../assets/img/anser-icon.png" />
        <p>
          タオル、運動できる服装、運動シューズをお願い致します。
          タオルは運動で使用するので、ご持参をお願いしております。
        </p>
      </div>
      <div class="l-question__content2__question">
        <img src="./../../../../assets/img/question-icon.png" />
        <p>雨の日はお休みですか？</p>
        <img src="./../../../../assets/img/anser-icon.png" />
        <p>館内で実施しておりますので、雨の日も行っております。</p>
      </div>
      <!-- <div class="l-question__content2__question">
        <img src="./../../../../assets/img/question-icon.png" />
        <p>子供向けの振替はどのようになっておりますか？</p>
        <img src="./../../../../assets/img/anser-icon.png" />
        <p>
          災害時や<br />体調不良の場合は当日12時までにご連絡をお願いしております。<br />その後1ヶ月までで入れる日程をこちらで調整をさせて頂き、希望曜日を選択して頂きます。<br />また、1ヶ月以内で入れない場合は振替は無くなりますので、ご注意ください。
        </p>
      </div> -->
      <!-- <div class="l-question__content2__question">
        <img src="./../../../../assets/img/question-icon.png" />
        <p>子供向けの休会は可能でしょうか？</p>
        <img src="./../../../../assets/img/anser-icon.png" />
        <p>
          当日12時までにご連絡をお願いしております。<br />その後1ヶ月までで入れる日程をこちらで調整をさせて頂き、希望曜日を選択して頂きます。<br />また、1ヶ月以内で入れない場合は振替は無くなります。
        </p>
      </div> -->
      <div class="l-question__content2__question">
        <img src="./../../../../assets/img/question-icon.png" />
        <p>怪我をした場合はどうなりますか？</p>
        <img src="./../../../../assets/img/anser-icon.png" />
        <p>
          全会員様にスポーツ保険に加入をお願いしております。<br />万が一けがをした場合などは対応できる体制を作っております。
        </p>
      </div>
    </ng-container>
  </div>
  <div class="l-question__content3" *ngIf="!isSeeMore">
    <button class="l-question__content3__button" (click)="seeMore()">
      もっと見る
    </button>
  </div>
</div>
