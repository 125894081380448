import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-kids-experience',
  templateUrl: './kids-experience.component.html',
  styleUrls: ['./kids-experience.component.scss'],
})
export class KidsExperienceComponent {
  constructor(
    private meta: Meta,
    private title: Title,
    private router: Router
  ) {}
  public element: any = '';

  public questionnaires: any = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];
  ngOnInit(): void {
    this.meta.addTag({
      name: 'description',
      content:
        'ダイナモBスタジオの習い事体験入店ページへようこそ。当スタジオでは、お子様の運動能力向上をサポートし、かけっこなどのスポーツ技術を磨くためのトレーニングを提供しています。また、さまざまなスポーツにおける改善点に対応するサービスも提供しており、お子様の能力向上を全面的にサポートします。',
    });
    this.title.setTitle(
      'かけっこ・体幹教室の習い事体験入店ページ | ダイナモBスタジオ'
    );
  }

  public onClickSection(section: string) {
    // this.element.setAttribute('style', 'overflow: auto;');
    // const panel = document.getElementById('--panel');

    console.log(location.pathname.length > 1);

    const element = document.getElementById(section);
    const rect = element.getBoundingClientRect();
    const elemtop: number = rect.top + window.pageYOffset;
    document.documentElement.scrollTop = elemtop - 100;
    // scroll(elemtop + 100, elemtop + 100);
  }
}
