<div class="l-tab">
  <div class="l-tab__title">
    <h3
      *ngIf="type !== 'notAll'"
      (click)="onTab(0)"
      [ngClass]="{
        '--select': number === 0
      }"
    >
      すべて
    </h3>
    <h3
      (click)="onTab(1)"
      [ngClass]="{
        '--select': number === 1
      }"
    >
      大人
    </h3>
    <h3
      (click)="onTab(2)"
      [ngClass]="{
        '--select': number === 2
      }"
    >
      子供
    </h3>
  </div>
  <div class="l-tab__main">
    <ng-content></ng-content>
  </div>
</div>
