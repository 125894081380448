<div class="checkbox">
  <div class="l-form-content__left" *ngIf="label">
    <p>{{ label }}</p>
  </div>

  <div class="l-form-content__right">
    <ul class="l-checkbox">
      <li *ngFor="let option of options">
        <label>
          <input #checkbox type="checkbox" [name]="control?.name" [value]="option.value" (change)="changValue()" />
          <span>{{ option.label }}</span>
        </label>
      </li>
    </ul>
  </div>
  <div class="l-error">
    <div *ngIf="control?.invalid && (control?.dirty || control?.touched)">
      <div class="error-messege" *ngIf="control?.errors?.required"> {{ label }}を入力してください。 </div>
    </div>
  </div>
</div>
