<div class="l-wrapper">
  <app-header></app-header>
  <div class="l-blog">
    <div class="l-blog__main" *ngIf="list">
      <ng-container *ngFor="let item of list.contents">
        <a class="l-blog__main__content" [href]="'/blog-content/' + item.id">
          <img
            class="l-blog__main__content__img"
            [src]="
              item?.eyecatch?.url
                ? item.eyecatch.url
                : '/assets/img/no_image.jpeg'
            "
            alt=""
          />
          <div [ngClass]="{ 'l-blog__main__content__category': item.category }">
            <p>{{ item.category?.name }}</p>
          </div>
          <div [ngClass]="{ 'l-blog__main__content__title': item.category }">
            <p>{{ item.title }}</p>
          </div>
          <div class="l-blog__main__content__description">
            <pre [innerHTML]="item.content"></pre>
          </div>
          <p class="l-blog__main__content__date">
            {{ formatDate(item.publishedAt) }}
          </p>
        </a>
      </ng-container>
      <div class="l-blog__pageing">
        <app-pageing
          [limit]="list.limit"
          [offset]="list.offset"
          [totalCount]="list.totalCount"
          (pageEmit)="pageEmit($event)"
        ></app-pageing>
      </div>
    </div>
    <div class="l-blog__category">
      <div class="l-paging-search" *ngIf="categoryList">
        <h3 class="c-paging-search-title">ジャンル</h3>
        <ul class="c-paging-search-list">
          <li *ngFor="let item of categoryList.contents" class="c-type-list">
            <a [href]="'/blog-list?category=' + item.id"
              >▶︎ ({{ item.name }})</a
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
  <app-footer></app-footer>
</div>
