import { Component, OnInit } from '@angular/core';
import { HttpService } from '../../base/http.service';
import { UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { SnackbarService } from '../../atom/snackbar/snackbar.service';
import { Meta, Title } from '@angular/platform-browser';
import { ContactService } from './contact.service';
import { SelectArrayPrefectures } from '../../base/prefectures';
import { of } from 'rxjs';
import { mergeMap, take } from 'rxjs/operators';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
})
export class ContactComponent implements OnInit {
  public formGroup: UntypedFormGroup = this.service.createFormGroup();
  public prefectures: any = SelectArrayPrefectures;
  constructor(
    private httpService: HttpService,
    private router: Router,
    private service: ContactService,
    public snackbarService: SnackbarService,
    private meta: Meta,
    private title: Title
  ) {}

  public isLoading = false;
  ngOnInit(): void {
    this.meta.addTag({
      name: 'description',
      content:
        'ダイナモBスタジオのお問い合わせページへようこそ。ご質問やご相談がございましたら、お気軽にお問い合わせください。当スタジオへの連絡先は0798-61-5344で、営業時間は9:00から18:00までです。どのようなお問い合わせも、スタッフ一同、心よりお待ちしております。',
    });
    this.title.setTitle('お問い合わせ | ダイナモBスタジオ');
  }

  public onLoading(isLoading: any) {
    this.isLoading = isLoading;
  }

  onChangeNumber() {
    this.service
      .zipcode(this.formGroup.value.postcode)
      .pipe(
        take(1),
        mergeMap((value: any) => {
          this.formGroup.patchValue({
            address: value.messege.address1,
            address1: value.messege.address2 + value.messege.address3,
          });
          return of(value);
        })
      )
      .subscribe(
        (value: any) => console.log(value),
        (error: any) => console.log(error),
        () => console.log('onCompleted')
      );
  }

  public onAccept() {
    if (this.formGroup.valid) {
      this.httpService.post('contact', this.formGroup.value).subscribe(
        (value) => {
          if (value.stuats === 200) {
            this.router.navigate(['/complete'], {
              queryParams: {
                iconSrc: '/assets/img/vector.png',
                title: 'お問い合わせありがとうございました。',
                messege:
                  'お問い合わせいただき、ありがとうございます。\nお問い合わせいただきました内容については近日中に担当者よりご連絡いたします。',
              },
            });
          } else {
            // this.onError.emit(value);
          }
        },
        (error) => console.log(error),
        () => console.log('onCompleted')
      );
    } else {
      this.snackbarService.pushValidationMessage();
    }
  }
}
