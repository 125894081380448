<div class="l-floating">
  <!-- <div class="l-floating__button">
    <div class="l-floating__button__tel">
      <img src="./../../../../assets/img/header-tel.png" />
      <p>0798-61-5344</p>
    </div>
    <p class="l-floating__button__contact">受付時間 10：00～18：00</p>
  </div>
  <img
    class="--speech-bubble c-pc"
    src="./../../../../assets/img/monay-speech-bubble.png"
    alt="今すぐ問い合わせる"
  />
  <img
    class="--speech-bubble c-sp"
    src="./../../../../assets/img/monay-speech-bubble-sp.png"
    alt="今すぐ問い合わせる"
  /> -->
  <a
    href="https://lin.ee/aYwugvk"
    class="l-floating__line"
    onclick="onConversion('https://lin.ee/aYwugvk')"
  >
    <img class="c-pc" src="/assets/img/new/line-icon.png" alt="line" />
    <img class="c-sp" src="/assets/img/new/line-sp-icon.png" alt="line" />
    <div class="l-floating__text">LINEで簡単！<br />無料問い合わせ</div>
    <img src="/assets/img/new/right-icon.png" alt="矢印" />
    <!-- Event snippet for LINEクリック conversion page -->
  </a>
</div>
