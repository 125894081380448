<form [formGroup]="formGroup">
  <div class="l-form-login">
    <app-input
      label="メールアドレス"
      type="email"
      placeholder="xxxx@gmail.com"
      formControlName="email"
    ></app-input>
    <app-input
      label="パスワード"
      type="password"
      placeholder="●●●●●●"
      formControlName="password"
    ></app-input>
    <div class="u-bottom l-send">
      <app-button
        type="button"
        [class]="'c-btn-submit'"
        [disabled]="!formGroup.valid"
        [value]="'ログインする'"
        (click)="onAccept()"
      ></app-button>
      <div class="u-password-link">
        <a href="/login-forget">> パスワードをお忘れの方はこちら</a>
      </div>
    </div>
  </div>
</form>
