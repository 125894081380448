import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-top-content-link',
  templateUrl: './top-content-link.component.html',
  styleUrls: ['./top-content-link.component.scss'],
})
export class TopContentLinkComponent implements OnInit {
  @Input() link = '';
  @Input() title = '';
  @Input() type = '';
  @Input() backgroundcolor = '';
  @Input() list: string[] = [];
  @Input() img: string = '';
  @Input() imgSp: string = '';

  constructor() {}

  ngOnInit(): void {}
}
