import {
  Component,
  OnInit,
  HostListener,
  Injectable,
  Inject,
} from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import { Event, NavigationEnd, RouterEvent, Router } from '@angular/router';
import { from } from 'rxjs';
import { filter } from 'rxjs/operators';
import { environment, client } from 'src/environments/environment';
const moment = require('moment');
@Component({
  selector: 'app-top',
  templateUrl: './top.component.html',
  styleUrls: ['./top.component.scss'],
})
@Injectable({
  providedIn: 'root',
})
export class TopComponent implements OnInit {
  constructor(
    private meta: Meta,
    private title: Title,
    @Inject(DOCUMENT) private dom: any,
    private router: Router // private router: ActivatedRoute
  ) {
    this.router.events
      .pipe(filter((e: Event): e is any => e instanceof NavigationEnd))
      .subscribe((e: any) => {
        this.createCanonicalUrl(`${environment.baseURL}${e.url}`);
      });
  }

  public data = {
    endpoint: 'blogs',
    queries: {
      orders: '-publishedAt',
      limit: 5,
      offset: 0,
      filters: '',
    },
  };

  public list: {
    contents: [
      {
        id: '';
        content: 0;
        title: '';
        category: { name: '' };
        eyecatch: { url: '' };
        publishedAt: '';
      }
    ];
    limit: 0;
    offset: 0;
    totalCount: 0;
  };

  public blogList: {
    contents: [
      {
        id: '';
        content: 0;
        title: '';
        category: { name: '' };
        eyecatch: { url: '' };
        publishedAt: '';
      }
    ];
    limit: 0;
    offset: 0;
    totalCount: 0;
  };

  public categoryList: {
    contents: [
      {
        name: '';
        id: '';
      }
    ];
  };

  ngOnInit(): void {
    this.getList();
    this.getBlogList();
    this.meta.addTag({
      name: 'description',
      content:
        'ダイナモBスタジオでは健康な体へ、体育が楽しくなるという意味がございます。「ダイナモ」という英語は「発電機」を意味します。これを我々のサービスの精神に見<br />立て、ユーザーが体を動かしてエネルギーを得ることを目指しています。',
    });
    this.title.setTitle('ダイナモBスタジオ');
  }

  public format(date: string, formatValue: string): any {
    return moment(date).format(formatValue);
  }

  public formatDate(date: string): any {
    return this.format(date, 'YYYY年MM月DD日');
  }

  public getList() {
    const category = 'umx69kp3yezf';
    this.data.queries.filters = category ? 'category[equals]' + category : '';

    from(client.get(this.data)).subscribe((data) => {
      console.log(data);
      this.list = data;
    });
  }
  public getBlogList() {
    // const category = 'umx69kp3yezf';
    // this.data.queries.filters = category ? 'category[equals]' + category : '';
    const data = {
      endpoint: 'blogs',
      queries: {
        orders: '-publishedAt',
        limit: 5,
        offset: 0,
        filters: '',
      },
    };

    from(client.get(data)).subscribe((data) => {
      console.log(data);
      this.blogList = data;
    });
  }

  public headerClass = 'c-header-transparent';
  public scllor = '';
  @HostListener('window:scroll', []) onWindowScroll() {
    if (window.pageYOffset) {
      this.headerClass = '';
      this.scllor = '';
    } else {
      this.headerClass = 'c-header-transparent';
      this.scllor = 'scllor';
    }
  }

  private createCanonicalUrl(url?: string) {
    const canURL = url === undefined ? this.dom.URL : url;
    const link: HTMLLinkElement = this.dom.createElement('link');
    link.setAttribute('rel', 'canonical');
    this.dom.head.appendChild(link);
    link.setAttribute('href', canURL);
  }

  setCanonicalURL() {
    this.deleteCanonicalURL();

    const link: HTMLLinkElement = this.dom.createElement('link');
    link.setAttribute('rel', 'canonical');
    link.setAttribute('href', this.dom.URL);

    this.dom.head.appendChild(link);
  }

  private deleteCanonicalURL() {
    let doms = Array.from(this.dom.head.querySelectorAll("[rel='canonical']"));
    doms.forEach((elm: any) => {
      this.dom.head.removeChild(elm);
    });
  }
}
