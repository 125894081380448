<div class="l-footer">
  <div class="l-footer__main-img">
    <a href="/">
      <img src="./../../../../assets/img/footer.png" class="--right c-pc" />
      <img src="./../../../../assets/img/footer-sp.png" class="--right c-sp" />
    </a>
  </div>

  <div class="l-footer__list">
    <div class="l-footer__list__left">
      <img alt="ダイナモBスタジオ" src="./../../../../assets/img/logo.png" />
      <p>〒662-0964 兵庫県西宮市弓場町９−３１</p>
    </div>
    <div class="l-footer__list__right">
      <a href="/about">会社概要</a>
      <a href="/recruit">採用情報</a>
      <a href="https://job-medley.com/facility/398118/" target="_blank"
        >ジョブメドレー</a
      >
      <a href="/">利用規約</a>
    </div>
    <p class="l-footer__copyright">©2023 ダイナモBスタジオ</p>
  </div>
</div>
