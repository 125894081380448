import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-price',
  templateUrl: './price.component.html',
  styleUrls: ['./price.component.scss'],
})
export class PriceComponent implements OnInit {
  constructor(private meta: Meta, private title: Title) {}

  ngOnInit(): void {
    this.meta.addTag({
      name: 'description',
      content:
        'ダイナモBスタジオの料金プランページへようこそ。当ページでは、お客様のニーズに合わせた様々なトレーニングプランとその詳細な料金体系をご紹介しています。個別トレーニングからグループセッションまで、幅広い選択肢を提供し、各プランの特徴やメリットを明確に説明しています。質の高いトレーニング体験をリーズナブルな価格で提供することを目指しています。是非、あなたにぴったりのプランを見つけてください。',
    });
    this.title.setTitle('料金プラン | ダイナモBスタジオ');
  }
}
