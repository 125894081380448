import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { environment } from './../../../environments/environment';
@Injectable({
  providedIn: 'root',
})
export class HttpService {
  /**
   * Http クライアントを実行する際のヘッダオプション
   * @private
   * @type {*}
   * @memberof HttpClientService
   * @description
   * 認証トークンを使用するために `httpOptions` としてオブジェクトを用意した。
   */
  private httpOptions: any = {
    // ヘッダ情報
    headers: new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      // 'Content-Type': 'application/json',
      'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
    }),
    body: null,
  };

  private host: string = environment.baseURL;

  /**
   * コンストラクタ. HttpClientService のインスタンスを生成する
   *
   * @param {Http} http Httpサービスを DI する
   * @memberof HttpClientService
   */
  constructor(private http: HttpClient) {
    // `Authorization` に `Bearer トークン` をセットする
  }

  /**
   * HTTP GET メソッドを実行する
   *
   * @returns {Promise<any[]>}
   * @memberof HttpClientService
   */
  public get(url: string, host: string = ''): Observable<any> {
    if (host) {
      this.host = host;
    }
    // return this.http.get<Observable<TemplateStringsArray>>(
    return this.http.get<Observable<any>>(`${this.host}/api/${url}`);
  }

  /**
   * HTTP POST メソッドを実行する
   *
   * @returns {Promise<any[]>}
   * @memberof HttpClientService
   */
  public post(url: string, body: {}): Observable<any> {
    return this.http.post<Observable<TemplateStringsArray>>(
      `${this.host}/api/${url}`,
      body,
      this.httpOptions
    );
  }

  /**
   * HTTP PUT メソッドを実行する
   *
   * @returns {Promise<any[]>}
   * @memberof HttpClientService
   */
  public put(url: string, body: {}): Observable<any> {
    return this.http.put<Observable<TemplateStringsArray>>(
      `${this.host}/api/${url}`,
      body,
      this.httpOptions
    );
  }

  /**
   * HTTP PUT メソッドを実行する
   *
   * @returns {Promise<any[]>}
   * @memberof HttpClientService
   */
  public delete(url: string): Observable<any> {
    return this.http.delete<Observable<TemplateStringsArray>>(
      `${this.host}/api/${url}`,
      this.httpOptions
    );
  }
}
