<div class="l-wrapper">
  <app-header></app-header>

  <div class="l-recruit">
    <div class="l-recruit__main">
      <img
        src="/assets/img/new/recruit-main.png"
        alt="採用情報"
        class="l-recruit__main__img c-pc"
      />
      <img
        src="/assets/img/new/recruit-main-sp.png"
        alt="採用情報"
        class="l-recruit__main__img c-sp"
      />
    </div>
    <div class="l-recruit__under">
      <h2 class="l-recruit__under__title">わたしたちと一緒に働きませんか？</h2>
      <span class="l-recruit__under__border"></span>
      <div class="l-recruit__under__description">
        <p>高齢者・子ども向け運動教室の運動指導トレーナー兼店舗運営スタッフ</p>
        <p>オープニングスタッフ/残業ほぼなし/転勤無</p>
        <p>
          契約社員/正社員/学歴不問/内定まで2週間/面接1回のみ/完全週休2日/転勤なし/残業月20h以内
        </p>
        <br />

        <p>高齢者の「健康」「元気」のために！</p>
        <p>子どもたちが感じる苦手を「得意」に！</p>
        <br />

        <p>兵庫県西宮市で、今夏オープン予定の「ダイナモBスタジオ」です。</p>
        <br />

        <p>高齢者は普段、このような事を気にしてます。</p>
        <p>「重たいものが持てない」</p>
        <p>「腰や膝が痛くて動くのが億劫」</p>
        <p>「他の施設だと続かない」</p>
        <p>「いまからやっても仕方がない」</p>
        <br />

        <p>一方、子供は親が気付かない部分でコンプレックスを持ってます。</p>
        <p>「走るのが遅いから体育はイヤ」</p>
        <p>「ドッチボールはボールが怖い」</p>
        <p>「逆上がりができないから鉄棒は嫌い」</p>
        <br />

        <p>
          当サービスではこのような、運動が苦手な方・運動をせずに私生活に影響が出ている方向けに、運動を好きになってもらえるような運動教室を目指してます。
        </p>
        <p>
          お客様から感謝の言葉を聞いたらきっとやりがいを感じて、楽しくなります。
        </p>
        <br />

        <p>
          現在オープン準備中のため、オープニングメンバーの1人として活躍が目指せます。
        </p>
        <p>アイディア、ご意見をどんどんご提案ください！</p>
        <p>「ラダーを使った運動でケンケンパーしたら面白そう」</p>
        <p>「メディシンボールを渡しながらしりとりするとおもしろそう」</p>
        <p>「ラダーを使った運動でリズムに乗せたら楽しそう」</p>
        <p>
          など、子どもたち、高齢者への指導や店舗運営について積極的に提案できる面白さがあります。
        </p>
        <br />

        <p>
          トレーナー未経験者でも社内研修と、必要に応じて社外研修も行っております.
        </p>
        <p>共に子供の成長・高齢者の元気にできるやりがいを感じませんか？</p>
      </div>
    </div>
    <div class="l-recruit__description">
      <h2 class="l-recruit__description__title">募集要項</h2>
      <img
        src="/assets/img/new/recruit-job.png"
        alt="採用情報"
        class="l-recruit__description__img c-pc"
      />
      <img
        src="/assets/img/new/recruit-job.png"
        alt="採用情報"
        class="l-recruit__description__img c-sp"
      />
      <div class="l-recruit__description__table">
        <img
          src="/assets/img/new/recruit-border.svg"
          alt="線"
          class="l-recruit__description__table__border c-pc"
        />
        <img
          src="/assets/img/new/recruit-border-sp.svg"
          alt="線"
          class="l-recruit__description__table__border c-sp"
        />
        <span>職種名</span>
        <span
          >高齢者・子ども向け運動教室の運動教室トレーナー兼店舗運営スタッフ(未経験可)
          オープニングスタッフ/残業ほぼなし/転勤無
        </span>

        <img
          src="/assets/img/new/recruit-border.svg"
          alt="線"
          class="l-recruit__description__table__border c-pc"
        />
        <img
          src="/assets/img/new/recruit-border-sp.svg"
          alt="線"
          class="l-recruit__description__table__border c-sp"
        />
        <span>仕事内容</span>
        <span>
          2023年夏ごろにオープン予定の『ダイナモBスタジオ』
          運動指導トレーナーとして、子どもと高齢者を中心とした指導をお任せします。<br />
          また店舗運営スタッフとしてお客様の手続き、清掃、販促等をしていただきます。<br />
          ダイナモBスタジオとは 主に小学生・高齢者を対象とした運動教室です。<br />
          小学生には『かけっこ』が速くなるプログラムを提供。運動が苦手で体育が嫌いな子、他のスポーツをしていて今後活躍したい子の基礎を作ります。
          また自重で体を動かすアメリカ発祥の『アニマルフロー』を導入。両手両足を使い、自分の体をしっかり動かすことで、心身ともにコントロールできるように、必ずアニマルフローをメニューに組んでいます。
          高齢者には100歳まで元気に、がコンセプトの『100トレ』体操や、『アニマルフロー』を導入する事で、いつまでも健康な体をキープできるようになってます。<br />
          多彩なキャリアをご用意!
          運動教室の店長はもちろん、ゆくゆくは会社全体の事業を統括するポジションなどにも挑戦することができます。<br />
          キャリアも収入もさらに上を目指していける環境です。<br />
          ※トレーニング以外に下記の業務もお任せします。
          <ul>
            <li>・新規会員の入会の手続き</li>
            <li>・入金のチェック</li>
            <li>・退会手続き</li>
            <li>・新規会員の獲得に向けた内覧会や体験会の実施</li>
            <li>・会員向けイベントの実施</li>
            <li>・ポスティング、SNSの更新</li>
            <li>・アルバイト研修業務</li>
            <li>・施設内清掃</li>
            <li>その他店舗運営に必要な業務全般</li>
          </ul>
        </span>

        <img
          src="/assets/img/new/recruit-border.svg"
          alt="線"
          class="l-recruit__description__table__border c-pc"
        />
        <img
          src="/assets/img/new/recruit-border-sp.svg"
          alt="線"
          class="l-recruit__description__table__border c-sp"
        />
        <span>募集資格</span>
        <span>
          業種・職種未経験、第二新卒歓迎!<br />
          ※学歴・ブランクも不問です。<br /><br />
          {{ "\以下に当てはまる方は大歓迎!/" }}<br />
          体を動かすことが好きな方<br />
          子供が好きな方 高齢者の対応に慣れている方<br />
          意見、アイディアを出せる方<br />
          ※運動指導資格所有者・経験者歓迎、特に[健康運動指導士][健康運動実践指導者]は優遇
        </span>

        <img
          src="/assets/img/new/recruit-border.svg"
          alt="線"
          class="l-recruit__description__table__border c-pc"
        />
        <img
          src="/assets/img/new/recruit-border-sp.svg"
          alt="線"
          class="l-recruit__description__table__border c-sp"
        />
        <span>募集背景</span>
        <span>
          {{ "<オープニングスタッフの募集です!>" }}<br />
          健康や運動に関する新規事業の一環として、高齢者の不調改善や子どもの基礎体力向上を目指した運動教室運営をスタートした当社。<br />
          新規事業として運動教室を選んだのは、運動によって解決できる問題が多いので、社会貢献が出来ると考えたためです。<br />
          上記理念を共有できる方は是非、ご応募ください！
        </span>

        <img
          src="/assets/img/new/recruit-border.svg"
          alt="線"
          class="l-recruit__description__table__border c-pc"
        />
        <img
          src="/assets/img/new/recruit-border-sp.svg"
          alt="線"
          class="l-recruit__description__table__border c-sp"
        />
        <span>雇用形態</span>
        <span>
          契約社員（正社員登用あり）<br />
          ※最長6ヶ月の試用期間があります。（条件変更なし）
        </span>

        <img
          src="/assets/img/new/recruit-border.svg"
          alt="線"
          class="l-recruit__description__table__border c-pc"
        />
        <img
          src="/assets/img/new/recruit-border-sp.svg"
          alt="線"
          class="l-recruit__description__table__border c-sp"
        />
        <span>勤務地</span>
        <span>
          兵庫県西宮市兵庫県弓場町 <br />
          転勤なし<br />
          2023年8月にオープン予定<br />
        </span>
        <img
          src="/assets/img/new/recruit-border.svg"
          alt="線"
          class="l-recruit__description__table__border c-pc"
        />
        <img
          src="/assets/img/new/recruit-border-sp.svg"
          alt="線"
          class="l-recruit__description__table__border c-sp"
        />
        <span>交通</span>
        <span>
          阪神「香櫨園」より徒歩10分<br />阪神「打出」より徒歩10分<br />JR「さくら夙川」より徒歩10分</span
        >

        <img
          src="/assets/img/new/recruit-border.svg"
          alt="線"
          class="l-recruit__description__table__border c-pc"
        />
        <img
          src="/assets/img/new/recruit-border-sp.svg"
          alt="線"
          class="l-recruit__description__table__border c-sp"
        />
        <span>勤務時間</span>
        <span>
          10:00~20:00(実働時間8時間)<br />
          利用者状況等により変動あり<br /><br />
          残業は月20時間以下。ほとんどありません。
        </span>

        <img
          src="/assets/img/new/recruit-border.svg"
          alt="線"
          class="l-recruit__description__table__border c-pc"
        />
        <img
          src="/assets/img/new/recruit-border-sp.svg"
          alt="線"
          class="l-recruit__description__table__border c-sp"
        />
        <span>給与</span>
        <span>
          最大30万円<br />
          基本給18万円以上 + インセンティブ ＋賞与（業績による）<br />

          経験や能力を考慮の上、決定いたします。<br />
          残業代は全額支給します。<br />
          インセンティブ制度あり<br />
          {{ "<想定年収例>年収300万円(入社1年目)" }}
        </span>

        <img
          src="/assets/img/new/recruit-border.svg"
          alt="線"
          class="l-recruit__description__table__border c-pc"
        />
        <img
          src="/assets/img/new/recruit-border-sp.svg"
          alt="線"
          class="l-recruit__description__table__border c-sp"
        />
        <span>休日・休暇</span>
        <span>
          週休2日制<br />
          年末年始休暇<br />
          慶弔休暇
        </span>

        <img
          src="/assets/img/new/recruit-border.svg"
          alt="線"
          class="l-recruit__description__table__border c-pc"
        />
        <img
          src="/assets/img/new/recruit-border-sp.svg"
          alt="線"
          class="l-recruit__description__table__border c-sp"
        />
        <span
          >福利厚生
          <p class="c-pc_inline">・</p>
          <br class="c-sp" />待遇</span
        >
        <span>
          <ul>
            <li>昇給年1回</li>
            <li>賞与（業績による）</li>
            <li>交通費全額支給</li>
            <li>社会保険完備(雇用・労災・健康・厚生年金)</li>
            <li>時間外手当(全額支給)</li>
            <li>役職手当</li>
            <li>全社員禁煙</li>
            <li>副業OK</li>
          </ul>
        </span>
        <img
          src="/assets/img/new/recruit-border.svg"
          alt="線"
          class="l-recruit__description__table__border c-pc"
        />
        <img
          src="/assets/img/new/recruit-border-sp.svg"
          alt="線"
          class="l-recruit__description__table__border c-sp"
        />
        <span>入社後の<br class="c-sp" />流れ</span>
        <span>
          入社後は、4週間の研修を実施します。子供向け、高齢者向けの研修を受けてもらいます。<br />
          また、運動教室の指導者としてスポーツトレーナーに必要な研修が終わり、店舗がオープンした後も分からないことや困ったことがあれば気軽に相談できる体制を作っていますので、安心して下さい。月に1回は、社内MTGを行い現状の課題点などコミュニケーションを取る形となります。
        </span>
      </div>
    </div>
    <div class="l-recruit__button">
      <a href="/contact">
        <button class="--green-button">申し込みはこちら</button>
      </a>
    </div>
  </div>
  <app-footer></app-footer>
</div>
