<div class="l-contact-link">
  <h2 class="l-contact-link__title">お申し込みはこちら</h2>

  <div class="l-contact-link__main">
    <!-- <a href="tel:0798-61-5344" class="l-contact-link__main__tel">
      <img class="c-pc" src="/assets/img/new/tel-icon.png" alt="電話番号" />
      <img class="c-sp" src="/assets/img/new/tel-sp-icon.png" alt="電話番号" />
      <div class="l-contact-link__main__text">
        <span>お電話でのお申込みはこちら</span><br />
        <span class="l-contact-link__main__text__contact"
          >TEL: 0798-61-5344 <br class="c-sp" />
          （受付時間10:00～18：00）</span
        >
      </div>
      <img src="/assets/img/new/right-icon.png" alt="矢印" />
    </a> -->
    <!-- <a href="/contact" class="l-contact-link__main__mail">
      <img class="c-pc" src="/assets/img/new/mail-icon.png" alt="メール" />
      <img class="c-sp" src="/assets/img/new/mail-sp-icon.png" alt="メール" />
      <div class="l-contact-link__main__text">メールでのお申込みはこちら</div>
      <img src="/assets/img/new/right-icon.png" alt="矢印" />
    </a> -->
    <!-- <a
      href="https://lin.ee/aYwugvk"
      class="l-contact-link__main__line"
      onclick="return onConversion(`https://lin.ee/aYwugvk`)"
    > -->
    <ng-container *ngIf="label === 'semi-pesonal'">
      <a
        href="https://lin.ee/aYwugvk"
        class="l-contact-link__main__line"
        onclick="gtag_report_conversion2('https://lin.ee/aYwugvk')"
      >
        <img class="c-pc" src="/assets/img/new/line-icon.png" alt="line" />
        <img class="c-sp" src="/assets/img/new/line-sp-icon.png" alt="line" />
        <div class="l-contact-link__main__text">
          申し込み等はLINEからお願い致します。
        </div>
        <img src="/assets/img/new/right-icon.png" alt="矢印" />
      </a>
    </ng-container>
    <ng-container *ngIf="label !== 'semi-pesonal'">
      <a
        href="https://lin.ee/aYwugvk"
        class="l-contact-link__main__line"
        onclick="onConversion('https://lin.ee/aYwugvk')"
      >
        <img class="c-pc" src="/assets/img/new/line-icon.png" alt="line" />
        <img class="c-sp" src="/assets/img/new/line-sp-icon.png" alt="line" />
        <div class="l-contact-link__main__text">
          申し込み等はLINEからお願い致します。
        </div>
        <img src="/assets/img/new/right-icon.png" alt="矢印" />
      </a>
    </ng-container>
  </div>
</div>
