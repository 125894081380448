import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-campaign-view',
  templateUrl: './campaign-view.component.html',
  styleUrls: ['./campaign-view.component.scss'],
})
export class CampaignViewComponent implements OnInit {
  @Input() isPlan = false;
  constructor() {}

  ngOnInit(): void {}
}
