<div class="l-wrapper">
  <app-header></app-header>
  <div class="l-blog">
    <div class="l-blog__content" *ngIf="blog">
      <div class="l-blog__content__date">
        {{ formatDate(blog.publishedAt) }}
      </div>
      <div class="l-blog__content__title">{{ blog.title }}</div>
      <img
        class="l-blog__main__img"
        [src]="
          blog?.eyecatch?.url ? blog.eyecatch.url : '/assets/img/no_image.jpeg'
        "
        alt=""
      />
      <div [innerHTML]="blog.content"></div>
    </div>

    <div class="l-blog__category">
      <div class="l-paging-search" *ngIf="categoryList">
        <h3 class="c-paging-search-title">ジャンル</h3>
        <ul class="c-paging-search-list">
          <li *ngFor="let item of categoryList.contents" class="c-type-list">
            <a [href]="'/blog-list?category=' + item.id"
              >▶︎ ({{ item.name }})</a
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
  <app-footer></app-footer>
</div>
