<div class="l-wrapper">
  <app-header></app-header>
  <app-loading *ngIf="isLoading"></app-loading>
  <div class="l-complate">
    <app-title label="Contact" title="お問い合わせ"></app-title>
    <div class="l-complate__main">
      <div class="l-complate__main__title" *ngIf="title">
        <h2 class="c-complate-title">{{ title }}</h2>
      </div>
      <div class="l-complate__main__content" *ngIf="messeges.length">
        <p [innerHTML]="messeges"></p>
      </div>
      <div class="l-complate__main__button">
        <a href="/">
          <button class="c-top-button">トップに戻る</button>
        </a>
      </div>
    </div>
  </div>
  <app-footer></app-footer>
</div>
