import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.scss'],
})
export class ScheduleComponent implements OnInit {
  constructor(private meta: Meta, private title: Title) {}

  ngOnInit(): void {
    this.meta.addTag({
      name: 'description',
      content:
        'ダイナモBスタジオのスケジュールページへようこそ。こちらでは、各種クラスやイベントの最新スケジュールをご確認いただけます。個々のライフスタイルや目標に合わせて、最適なトレーニング時間を見つけることができます。忙しい日々の中でも、健康とフィットネスを維持するための柔軟なプランニングをサポートします。ダイナモBスタジオでのトレーニングを計画する際の貴重なリソースとしてご利用ください。',
    });
    this.title.setTitle('レッスンスケジュール | ダイナモBスタジオ');
  }
}
