<div class="l-wrapper">
  <app-header></app-header>
  <div class="l-short-lesson">
    <img
      class="c-pc"
      src="/assets/img/new/short-lesson-2024-natu-main.png"
      alt="春限定の短期レッスン| かけっこ教室"
    />
    <img
      class="c-sp"
      src="/assets/img/new/short-lesson-2024-natu-main-sp.png"
      alt="春限定の短期レッスン| かけっこ教室"
    />

    <div class="l-short-lesson__main">
      <div class="l-short-lesson__main__service">
        <h3 class="l-short-lesson__main__service__title">
          ダイナモBスタジオってどんな所？
        </h3>
        <div class="l-short-lesson__main__service__content">
          <p>
            「体の使い方を学ぶ」をコンセプトに展開している当運動教室では、運動が苦手な方を基準レベルまで引き上げ、得意な方をさらに上達させることを目指しています。
          </p>
        </div>
      </div>

      <div class="l-short-lesson__main__programme">
        <h3 class="l-short-lesson__main__programme__title">
          短期かけっこ教室のコーチ
        </h3>
        <div class="l-short-lesson__main__programme__description">
          <div class="l-short-lesson__main__programme__description__profile">
            <img
              src="./../../../../assets/img/new/oosaki.png"
              alt="大崎 健太"
            />
          </div>
          <div class="l-short-lesson__main__programme__description__career">
            神戸で陸上選手兼短距離コーチとして活動しております。<br />日本選手権出場や関西実業団での3連覇の実績を持ち、ダイナモBスタジオのプログラム作成にも一部貢献しています。
            <br />お子様の体力向上や正しい走り方にご興味がある保護者様、また春休みに運動不足を解消したいとお考えの方は、ぜひお越しください。
          </div>
        </div>
      </div>

      <!-- <div class="l-short-lesson__main__programme">
        <h3 class="l-short-lesson__main__programme__title">
          バレエ×ストレッチの短期教室について
        </h3>
        <div class="l-short-lesson__main__programme__description">
          <div class="l-short-lesson__main__programme__description__profile">
            <img
              src="./../../../../assets/img/new/baree.jpeg"
              alt="バレエ×ストレッチの短期教室について"
            />
          </div>
          <div class="l-short-lesson__main__programme__description__career">
            子供たちの柔軟性向上のために、バレエのレッスンを取り入れたストレッチを実施しています。<br />普段スポーツをされる方にも、短期教室で身体の柔軟性を高める機会を提供しています。ぜひお試しください。
          </div>
        </div>
      </div> -->

      <div class="l-short-lesson__main__day">
        <h2 class="l-short-lesson__main__day__title">開催日</h2>
        <div class="l-short-lesson__main__day__main">
          <div>
            <div>かけっこ教室(最大6名)</div>

            <div>8月13日</div>
            <div>11:00～12:00</div>
            <div>(未就学児の枠)</div>

            <div style="margin-top: 32px">
              <div>8月14日</div>
              <div>11:00～12:00</div>
              <div>(小学生の枠 受付終了)</div>
            </div>
          </div>
        </div>
      </div>

      <div class="l-short-lesson__main__day">
        <h2 class="l-short-lesson__main__day__title">特別枠</h2>
        <div class="l-short-lesson__main__day__main">
          <div>
            <div>かけっこ・パーソナル</div>
            <div>受付 1名のみ</div>

            <div>日付は大崎選手と調整</div>
            <div>特別枠のみ料金が5,000円（税抜き）</div>

            <div style="margin-top: 32px">
              <div>条件</div>
              <div>・何かしらスポーツをされている</div>
              <div>・5年生以上のお子様</div>
            </div>
          </div>
        </div>
      </div>

      <div class="l-short-lesson__main__price">
        <h2 class="l-short-lesson__main__price__title">料金について</h2>
        <div class="l-short-lesson__main__price__main">
          <div>料金プラン</div>
          <div>料金</div>

          <div>一回</div>
          <div>1,500円（税抜き）</div>
        </div>
      </div>

      <div class="l-short-lesson__main__contact-link">
        <app-contact-link></app-contact-link>
      </div>
    </div>
  </div>
  <app-footer></app-footer>
</div>
