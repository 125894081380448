import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  Optional,
  Self,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NgControl,
} from '@angular/forms';

@Component({
  selector: 'app-selectbox',
  templateUrl: './selectbox.component.html',
  styleUrls: ['./selectbox.component.scss'],
})
export class SelectboxComponent implements ControlValueAccessor, OnInit {
  @Input() type = 'default';
  @Input() label = '';
  @Input() lastLabel = '';
  @Input() blank: string = null;
  @Input() options: any = [];
  @Input() layout = 'default';
  @Input() selected: string | number = '';
  @Input() require = false;

  @ViewChild('selectbox') element: ElementRef;

  onChange: (value: string | number) => void;
  onTouched: () => void;

  constructor(
    @Self() @Optional() public control: NgControl,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    if (this.control) {
      this.control.valueAccessor = this;
    }
  }

  ngOnInit(): void {}

  emit() {
    this.changeDetectorRef.detectChanges();
  }

  get required() {
    const validator = this.control?.control.validator;
    return validator
      ? validator({} as AbstractControl)?.required || false
      : false;
  }

  getOptionLabel(value: any) {
    // return this.options.find((option) => option.value === value)?.label || '';
  }

  // ControlValueAccessor
  writeValue(value: any) {
    if (this.element) {
      this.element.nativeElement.value = value;
      this.emit();
    }
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }
}
