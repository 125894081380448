<div class="l-wrapper">
  <app-header></app-header>

  <div class="l-personal-consent-form">
    <app-title label="consent" title="同意書"></app-title>

    <div class="l-personal-consent-form__main">
      <div style="margin-top: 60px">
        <h3 class="l-personal-consent-form__main__title">
          【１．プログラムに付随した危険性および異常感 】
        </h3>
      </div>
      <div>
        <p class="l-personal-consent-form__main__content">
          運動中、以下の症状が現れる可能性があります。
          ・血圧異常・めまい・不整脈・頻脈や徐脈など
          場合によっては心臓発作、脳卒中および死亡事故も含まれます。
          それらに対しては全て自己責任となります。基礎疾患などがある場合は、入会前に必ず医師に確認の上、弊社に報告をお願いしております。
          また、弊社では運動中に適切な管理体制をとり、これらの事故の危険性を最小限にするために努めています。
        </p>
      </div>

      <h3 class="l-personal-consent-form__main__title">
        【２．サービスの効果】
      </h3>
      <div>
        <p class="l-personal-consent-form__main__content">
          トレーニングプログラムへの参加により、改善を示唆する十分な証拠に基づいておりトレーニングプログラムへの参加により、改善を示唆する十分な証拠に基づいておりますが、絶対的な効果を保証するものではありません。
          ますが、絶対的な効果を保証するものではありません。
        </p>
      </div>

      <h3 class="l-personal-consent-form__main__title">【３．体調管理】</h3>
      <div>
        <p class="l-personal-consent-form__main__content">
          トレーニング中に体調が悪くなった場合、今まで経験のない異常な疲労感や、頻脈・動悸・胸の痛み、その他の異常感を感じた場合は、速やかに担当のトレーナーに報告してください。
          また、事前に過去の病歴・現在の服用中の薬についてはすべてお話して頂く義務が生じます。
        </p>
      </div>

      <h3 class="l-personal-consent-form__main__title">
        【４．個人情報の保護】
      </h3>
      <div>
        <p class="l-personal-consent-form__main__content">
          プログラムに参加されたことにより得られたお客様の情報は、お客様の同意なしに、第三者に提供されること、もしくは開示されることは一切ありません。
          ただし、お客様のプライバシーを保護した上で、得られた結果を統計のために利用させて頂くことはあります。
        </p>
      </div>

      <h3 class="l-personal-consent-form__main__title">
        【５．予約について 】
      </h3>
      <div>
        <p class="l-personal-consent-form__main__content">
          予約は店舗LINEにて承っております。
          ご予約の変更については、店舗LINEにて相談を承ります。
          なお、当日のご予約変更は対応出来かねますのでご了承ください。
        </p>
      </div>

      <h3 class="l-personal-consent-form__main__title">
        【６．キャンセルについて 】
      </h3>
      <div>
        <p class="l-personal-consent-form__main__content">
          レッスン当日のキャンセルについては提供価格の全額を徴収させて頂きますので、キャンセルご希望の方は前営業日の18時までにご連絡ください。
          キャンセルする際は、店舗の電話、または店舗LINEまでご連絡ください。
          なお、災害時や公共交通機関の大幅な遅れなどによってやむを得ない理由の場合は、キャンセル料金は発生致しません。
        </p>
      </div>

      <h3 class="l-personal-consent-form__main__title">
        【７．月会費のお支払いについて 】
      </h3>
      <div>
        <p class="l-personal-consent-form__main__content">
          月会費及び月額プランのお支払いは、各種決済代行サービスにてお支払い頂きます。(クレジット）
          なお、入会日（トレーニング開始日）によっては、初月のご請求金額が異なる場合がございます。（週割引き制度）
          また、セミパーソナルの際は都度払いとさせて頂きます。
        </p>
      </div>

      <h3 class="l-personal-consent-form__main__title">
        【８．ご返金について 】
      </h3>
      <div>
        <p class="l-personal-consent-form__main__content">
          基本的にご返金は受け付けておりません。
          ただし、急な引越しや転勤など、やむを得ない理由が生じた場合は、店舗の相談窓口へご相談ください。
        </p>
      </div>

      <h3 class="l-personal-consent-form__main__title">
        【９．対応不可能なサービス内容 】
      </h3>
      <div>
        <p class="l-personal-consent-form__main__content">
          医療行為、マッサージ、鍼灸、その他類似するものについては、提供は出来ませんので予めご了承ください。
        </p>
      </div>

      <h3 class="l-personal-consent-form__main__title">
        【１０．入会後のご連絡方法について 】
      </h3>
      <div>
        <p class="l-personal-consent-form__main__content">
          ご入会後の店舗とのご連絡方法は、LINEオフィシャルアカウントのみとさせて頂きます。
          緊急時を除き、メールや電話による対応は出来かねますので、予めご了承下さい。
        </p>
      </div>

      <h3 class="l-personal-consent-form__main__title">
        【１１．未成年の方のご利用について 】
      </h3>
      <div>
        <p class="l-personal-consent-form__main__content">
          20歳未満の方が保護者の同伴なしでご利用になる場合は必ず親の同意が必要になります。
          ご入会時は必ず保護者が同席の上、手続きを行って頂きます。
        </p>
      </div>
    </div>

    <form class="l-personal-consent-form__form" [formGroup]="formGroup">
      <app-input
        label="お名前"
        [require]="true"
        placeholder=""
        formControlName="name"
      ></app-input>

      <!-- <app-input
        label="スタッフ名"
        [require]="true"
        placeholder=""
        formControlName="trainerName"
      ></app-input> -->

      <app-selectbox
        label="スタッフ名"
        formControlName="trainerName"
        [options]="staffOption"
        [selected]="formGroup.value.trainerName"
      ></app-selectbox>

      <app-input
        label="メールアドレス"
        [require]="true"
        placeholder=""
        formControlName="email"
      ></app-input>

      <div class="l-form__content-checkbox">
        <app-checkbox
          [name]="'agree'"
          label=""
          [options]="[{ value: true, label: '利用規約にチェック' }]"
          formControlName="agree"
          [checked]="formGroup.value.agree"
        ></app-checkbox>
      </div>
      <button
        translate
        class="c-contact-button"
        type="button"
        (click)="onAccept()"
      >
        送信する
      </button>
    </form>
  </div>
  <app-footer></app-footer>
</div>
