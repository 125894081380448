<div class="l-wrapper">
  <app-header></app-header>
  <div class="l-schedule">
    <img
      src="/assets/img/new/schedule-main.png"
      alt="レッスンスケジュール"
      class="c-pc"
    />
    <img
      src="/assets/img/new/schedule-main-sp.png"
      alt="レッスンスケジュール"
      class="c-sp"
    />
    <div class="l-schedule__main">
      <img
        src="/assets/img/new/schedule.png"
        alt="スケジュール表"
        class="c-pc"
      />
      <img
        src="/assets/img/new/schedule-sp.png"
        alt="スケジュール表"
        class="c-sp"
      />
    </div>
  </div>
  <app-footer></app-footer>
</div>
