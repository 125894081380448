import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, Optional, Self, ViewChildren } from '@angular/core';
import { ControlValueAccessor, AbstractControl, NgControl } from '@angular/forms';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss']
})
export class CheckboxComponent implements OnInit, ControlValueAccessor {
  @Input() type = 'default';
  @Input() label = '';
  @Input() name = '';
  @Input() checked = '';
  @Input() options: any = [];

  innerValue = '';
  @ViewChildren('checkbox') elements: ElementRef[] = [];

  constructor(
    @Self() @Optional() public control: NgControl,
    private changeDetectorRef: ChangeDetectorRef // public validationMessageService: ValidationMessageService
  ) {
    if (this.control) {
      this.control.valueAccessor = this;
    }
  }

  // ControlValueAccessor
  onChange: (value: any) => void;
  onTouched: () => void;

  ngOnInit() {}

  emit() {
    this.changeDetectorRef.detectChanges();
  }

  propagateChange = (_: any) => {};

  get required() {
    const validator = this.control?.control?.validator;
    return validator ? validator({} as AbstractControl)?.required || false : false;
  }

  get isSideLayout() {
    return this.type === 'side';
  }

  changValue() {
    this.onChange(
      this.elements
        .map(element => element.nativeElement)
        .filter(element => element.checked)
        .map(element => element.value)
    );
  }

  // ControlValueAccessor
  writeValue(values: any[]) {
    setTimeout(() => {
      this.elements.map(element => element.nativeElement).forEach(element => (element.checked = (values || []).includes(element.value)));
      this.changeDetectorRef.detectChanges();
    });
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }
}
