<div class="l-wrapper">
  <app-header></app-header>
  <div class="l-facility">
    <img
      src="/assets/img/new/facility-main.png"
      alt="設備について"
      class="c-pc"
    />
    <img
      src="/assets/img/new/facility-main-sp.png"
      alt="設備について"
      class="c-sp"
    />
    <div class="l-facility__main">
      <h3 class="l-facility__main__title">設備一覧</h3>

      <div class="l-facility__main__list">
        <div class="l-facility__main__list__content">
          <img
            src="/assets/img/new/senpuuki.jpg"
            width="390px"
            height="314px"
            alt="業務用扇風機"
          />
          <p class="l-facility__main__list__content__title">業務用扇風機</p>
          <span class="l-facility__main__list__content__description"
            >施設内の空気を循環してます。</span
          >
        </div>
        <div class="l-facility__main__list__content">
          <img
            src="/assets/img/new/locker.jpg"
            width="390px"
            height="314px"
            alt="ロッカー|オープンロッカー"
          />
          <p class="l-facility__main__list__content__title">荷物入れ</p>
          <span class="l-facility__main__list__content__description"
            >持ってきた荷物はオープンロッカーに入れて頂きます。</span
          >
        </div>
        <div class="l-facility__main__list__content">
          <img
            src="/assets/img/new/bouhan_camera.jpg"
            width="390px"
            height="314px"
            alt="防犯カメラ"
          />
          <p class="l-facility__main__list__content__title">防犯カメラ</p>
          <span class="l-facility__main__list__content__description"
            >運動中の様子を一時的に録画しており、問題があっても対応できるようにしております。</span
          >
        </div>
        <div class="l-facility__main__list__content">
          <img
            src="/assets/img/new/siba.jpg"
            width="390px"
            height="314px"
            alt="人工芝"
          />
          <p class="l-facility__main__list__content__title">人工芝</p>
          <span class="l-facility__main__list__content__description"
            >怪我をしないように下にクッションも敷いてます。<br />素足で運動できるような人工芝となっております。</span
          >
        </div>
        <div class="l-facility__main__list__content">
          <img
            src="/assets/img/new/eakon.jpg"
            width="390px"
            height="314px"
            alt="ダイキンのエアコン　うるさらX"
          />
          <p class="l-facility__main__list__content__title">
            ダイキンのエアコン
          </p>
          <span class="l-facility__main__list__content__description"
            >空気洗浄機と加湿・除湿の役割を担って頂く製品です。運動に最適な環境にしております。</span
          >
        </div>
        <div class="l-facility__main__list__content">
          <img
            src="/assets/img/new/sintyou.jpg"
            width="390px"
            height="314px"
            alt="身長計"
          />
          <p class="l-facility__main__list__content__title">身長計</p>
          <span class="l-facility__main__list__content__description"
            >壁掛けで使用できるタイプを使用してます。</span
          >
        </div>
        <div class="l-facility__main__list__content">
          <img
            src="/assets/img/new/kado.jpg"
            width="390px"
            height="314px"
            alt="角ガード"
          />
          <p class="l-facility__main__list__content__title">
            コーナー・L字ガード
          </p>
          <span class="l-facility__main__list__content__description"
            >運動する際に危険な部分は角ガードを接着しております。</span
          >
        </div>
      </div>
    </div>

    <div class="l-facility__main">
      <h3 class="l-facility__main__title">器具一覧</h3>

      <div class="l-facility__main__list">
        <div class="l-facility__main__list__content">
          <img
            src="/assets/img/new/tetsubou.jpg"
            width="390px"
            height="314px"
            alt="鉄棒"
          />
          <p class="l-facility__main__list__content__title">鉄棒</p>
          <span class="l-facility__main__list__content__description"
            >室内で使用できるものを使用しております。</span
          >
        </div>
        <div class="l-facility__main__list__content">
          <img
            src="/assets/img/new/trx.jpg"
            width="390px"
            height="314px"
            alt="TRX"
          />
          <p class="l-facility__main__list__content__title">TRX</p>
          <span class="l-facility__main__list__content__description"
            >体幹と全身トレーニングができるので器具となっております。</span
          >
        </div>
        <div class="l-facility__main__list__content">
          <img
            src="/assets/img/new/rada-.jpg"
            width="390px"
            height="314px"
            alt="ラダー"
          />
          <p class="l-facility__main__list__content__title">ラダー</p>
          <span class="l-facility__main__list__content__description"
            >足を速くするための道具です。</span
          >
        </div>
        <div class="l-facility__main__list__content">
          <img
            src="/assets/img/new/marker-cone.jpg"
            width="390px"
            height="314px"
            alt="マーカーコーン"
          />
          <p class="l-facility__main__list__content__title">マーカーコーン</p>
          <span class="l-facility__main__list__content__description"
            >足腰を鍛えるために使用します。</span
          >
        </div>
        <div class="l-facility__main__list__content">
          <img
            src="/assets/img/new/mini-hurdle.jpg"
            width="390px"
            height="314px"
            alt="ミニハードル"
          />
          <p class="l-facility__main__list__content__title">ミニハードル</p>
          <span class="l-facility__main__list__content__description"
            >足を意識して上げる運動を促します。</span
          >
        </div>
        <div class="l-facility__main__list__content">
          <img
            src="/assets/img/new/gimuniku.jpg"
            width="390px"
            height="314px"
            alt="ギムニク"
          />
          <p class="l-facility__main__list__content__title">ギムニク</p>
          <span class="l-facility__main__list__content__description"
            >通常のボールよりも柔らかくあたってもいたくないボールです。<br />ボールの投げ方等に使用します。</span
          >
        </div>
        <div class="l-facility__main__list__content">
          <img
            src="/assets/img/new/matto.jpg"
            width="390px"
            height="314px"
            alt="ストレッチマット"
          />
          <p class="l-facility__main__list__content__title">ストレッチマット</p>
          <span class="l-facility__main__list__content__description"
            >柔軟運動・ストレッチの際に使用します。</span
          >
        </div>
      </div>
    </div>
  </div>
  <app-footer></app-footer>
</div>
