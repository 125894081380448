import { Injectable } from '@angular/core';
import { HttpService } from '../../base/http.service';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class ContactService {
  constructor(private httpService: HttpService) {}

  public createFormGroup(): UntypedFormGroup {
    return new UntypedFormGroup({
      name: new UntypedFormControl('', [Validators.required]),
      kana: new UntypedFormControl('', [Validators.required]),
      email: new UntypedFormControl('', [Validators.required, Validators.email]),
      tel: new UntypedFormControl('', [Validators.required]),
      postcode: new UntypedFormControl('', [Validators.required]),
      address: new UntypedFormControl('', [Validators.required]),
      address1: new UntypedFormControl('', [Validators.required]),
      address2: new UntypedFormControl('', [Validators.required]),
      content: new UntypedFormControl('', [Validators.required]),
    });
  }

  public zipcode(value: string): any {
    return this.httpService.get('zip/address/' + value);
  }
}
