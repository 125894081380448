<div class="l-wrapper">
  <app-header></app-header>
  <div class="l-preschooler">
    <img
      src="/assets/img/new/preschooler-main.png"
      alt="無料体験 | 未就学児限定 | かけっこ・体幹教室 | 苦手な体育を克服 | さらに運動能力アップ| 足が速くなる"
      class="c-pc"
    />
    <img
      src="/assets/img/new/preschooler-main-sp.png"
      alt="無料体験 | 未就学児限定 | かけっこ・体幹教室 | 苦手な体育を克服 | さらに運動能力アップ| 足が速くなる"
      class="c-sp"
    />
    <div class="l-preschooler__main">
      <div class="l-preschooler__main__service">
        <!-- <img
          src="./../../../../assets/img/new/price_preschooler.png"
          alt=""
          style="display: flex; margin: 0 auto 32px auto"
        /> -->
        <div class="l-preschooler__main__service__motion">
          <div class="l-preschooler__main__service__motion__text">
            <h3 class="l-preschooler__main__service__motion__text__title">
              ダイナモBスタジオの<br />
              未就学児の習い事のポイント
            </h3>
            <div class="l-preschooler__main__service__motion__text__box1">
              <ul>
                <li>毎週通いたいと思える</li>
                <li>楽しく継続できる</li>
                <li>運動能力がアップできる</li>
                <li>考える力を身につける</li>
              </ul>
            </div>
          </div>
        </div>
        <img
          src="./../../../../assets/img/new/preschooler-tanosi.jpeg"
          alt="未就学児の楽しそうな画像"
        />
      </div>

      <div class="l-preschooler__main__service__today">
        <h3 class="l-preschooler__main__service__today__title">
          当日の持ち物について
        </h3>
        <div class="l-preschooler__main__service__today__box">
          <p class="l-preschooler__main__service__today__box__list">
            ・水分補給できるもの<br />・タオル<br />・運動できる服装<br />
            ・入会用のクレジットカード
          </p>
          <p class="l-preschooler__main__service__today__box__list">
            ※貴重品と高価なものは持参をご遠慮お願い致します。
          </p>
        </div>

        <div class="l-preschooler__main__service">
          <!-- <img
          src="./../../../../assets/img/new/price_preschooler.png"
          alt=""
          style="display: flex; margin: 0 auto 32px auto"
        /> -->
          <div class="l-preschooler__main__service__motion">
            <img src="./../../../../assets/img/new/kids-service-1.png" alt="" />
            <div class="l-preschooler__main__service__motion__text">
              <h3 class="l-preschooler__main__service__motion__text__title">
                適切な運動指導を受けましょう
              </h3>
              <h3 class="l-preschooler__main__service__motion__text__title">
                ただ、走るだけでは速くならない
              </h3>
              <p
                class="l-preschooler__main__service__motion__text__description"
              >
                ダイナモBスタジオのかけっこ・体幹のレッスンを一度受けてみませんか？
                学校や他の施設では教えてくれない、フォームや運動方法を適切にわかりやすく指導しております。
                指導者も運動を教えるプロフェッショナルとして普段から活動をしております。
              </p>
              <!-- <a
              href="/contact"
              class="l-preschooler__main__service__motion__text__button"
              ><div>Webで予約</div></a
            > -->
            </div>
          </div>

          <div class="l-preschooler__main__service__today">
            <h3 class="l-preschooler__main__service__today__title">
              当日の持ち物について
            </h3>
            <div class="l-preschooler__main__service__today__box">
              <p class="l-preschooler__main__service__today__box__list">
                ・水分補給できるもの<br />・タオル<br />・運動できる服装<br />
                ・入会用のクレジットカード
              </p>
              <p class="l-preschooler__main__service__today__box__list">
                ※貴重品と高価なものは持参をご遠慮お願い致します。
              </p>
            </div>
          </div>
        </div>

        <div class="l-preschooler__main__service__worries">
          <h3 class="l-preschooler__main__service__worries__title">
            突然ですが、子供の時に<br />こんなことを考えてませんでしたか？
          </h3>
          <div class="l-preschooler__main__service__worries__content">
            <img
              src="./../../../../assets/img/new/kids-preschooler-flow.png"
              alt="走ったらこけてしまう|体の使い方が何か変だと感じる|急に力任せにボールを投げている|もっと運動をして体力をつけてほしい|楽しく運動能力をアップしてほしい|姿勢や内・がに股等、気になる点がある"
            />
          </div>
        </div>
        <img
          src="./../../../../assets/img/new/kids-preschooler-flow2.png"
          alt="運動ができるようにするためには「ダイナモBスタジオ」"
          class="c-pc"
        />
        <img
          src="./../../../../assets/img/new/kids-preschooler-flow2-sp.png"
          alt="運動ができるようにするためには「ダイナモBスタジオ」"
          class="c-sp"
          style="width: 100%"
        />
        <img
          src="./../../../../assets/img/new/kids-preschooler-hierarchy.png"
          alt="サッカー、野球、空手、水泳、陸上、マット運動、逆上がり、飛び箱、登り棒、ウンテイ、走る、投げる、飛ぶ、キャッチ、まっすぐ寝る、寝転ぶ【ダイナモBスタジオはこの部分を教えます】"
          style="width: 300px; margin: 0 auto"
        />
        <div class="l-preschooler__main__service__program">
          <h3 class="l-preschooler__main__service__program__title">
            プログラムの一例
          </h3>
          <div
            class="l-preschooler__main__service__program__box"
            style="height: 264px"
          >
            <p class="l-preschooler__main__service__program__box__list">
              ・正しい準備運動<br />・マット運動<br />（カエルジャンプ,四つん這いで動く...etc）<br />・体幹トレーニング<br />・走る練習<br />
              ・不安定な環境でぶら下がり<br />
              ・ボールを投げる・取る<br />
              ・変形ダッシュ<br />
            </p>
          </div>
        </div>

        <div class="l-preschooler__main__service__program">
          <h3 class="l-preschooler__main__service__program__title">
            効果とお客様の声
          </h3>
          <div
            class="l-preschooler__main__service__program__youtube"
            style="height: auto"
          >
            <iframe
              src="https://www.youtube.com/embed/8IdlTWxpXVc"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </div>
        </div>
        <!-- <div class="l-preschooler__main__service__program">
        <h3 class="l-preschooler__main__service__program__title">
          どんな効果がありますか？
        </h3>
        <div
          class="l-preschooler__main__service__program__box"
          style="height: auto"
        >
          <p class="l-preschooler__main__service__program__box__list">
            ・正しい準備運動で怪我をしにくい体作り<br />
            ・足が速くなる<br />
            ・スポーツで技術の取得が早くなる<br />
            ・野球やサッカー等で使える技術を学べる<br />
            ・学校のマット運動が出来るようになる<br />
            ・スポーツテストの結果が伸びる<br />
          </p>
        </div>
      </div> -->

        <!-- <div class="l-preschooler__main__service__program">
        <h3 class="l-preschooler__main__service__program__title">
          入会した理由は？
        </h3>
        <div
          class="l-preschooler__main__service__program__box"
          style="height: auto"
        >
          <p class="l-preschooler__main__service__program__box__list">
            ・運動を得意になってほしい<br />
            ・足が速くなってほしい<br />
            ・姿勢がよくなってほしい<br />
            ・スポーツで結果を残したい<br />
            ・体力をつけてほしい<br />
          </p>
        </div>
      </div> -->
        <app-kids-service></app-kids-service>

        <div class="l-preschooler__main__service__flow">
          <div class="l-preschooler__main__service__flow__content1">
            <h2 class="l-preschooler__main__service__flow__content1__title">
              体験入店の流れ
            </h2>
          </div>
          <div class="l-preschooler__main__service__flow__content2">
            <img
              src="./../../../../assets/img/new/kids-ninthview1.png"
              alt="電話、Web、LINEにて連絡があり次第、予約を取らせて頂きます。"
            />
            <img
              src="./../../../../assets/img/new/kids-top-ninthview-right.png"
              class="--right c-pc"
            />
            <img
              src="./../../../../assets/img/new/kids-top-ninthview-bottom.png"
              class="--right c-sp"
            />
            <img
              src="./../../../../assets/img/new/kids-ninthview2.png"
              alt="簡単な質問用紙に記入をお願いしております。運動できる服装と水分の用意をお願い致します。"
            />
            <img
              src="./../../../../assets/img/new/kids-top-ninthview-right.png"
              class="--right c-pc"
            />
            <img
              src="./../../../../assets/img/new/kids-top-ninthview-bottom.png"
              class="--right c-sp"
            />
            <img
              src="./../../../../assets/img/new/kids-ninthview-preschooler3.png"
              alt="約40分ほどの運動を体験して頂きます。どのような運動の効果があるかスタッフが保護者の方に説明もさせて頂きます。"
            />
            <img
              src="./../../../../assets/img/new/kids-ninthview4.png"
              alt="スタッフが全員にお声掛けさせていただきます。入会希望の方はこちらで入会手続きをさせて頂きます。"
            />
            <img
              src="./../../../../assets/img/new/kids-top-ninthview-right.png"
              class="--right c-pc"
            />
            <img
              src="./../../../../assets/img/new/kids-top-ninthview-bottom.png"
              class="--right c-sp"
            />
            <img
              src="./../../../../assets/img/new/kids-ninthview5.png"
              alt="簡単なアンケートを記入して頂き終了となります。"
            />
          </div>
        </div>

        <div class="l-preschooler__main__service__flow">
          <div class="l-preschooler__main__service__flow__content1">
            <h2 class="l-preschooler__main__service__flow__content1__title">
              お客様の反応
            </h2>
          </div>
          <div class="l-preschooler__main__service__flow__block">
            <splide
              [options]="{ rewind: true, rewindByDrag: true, arrows: false }"
            >
              <splide-slide
                *ngFor="let questionnaire of questionnaires; index as i"
              >
                <img
                  width="100%"
                  height="100px"
                  [src]="
                    './../../../../assets/img/new/questionnaire_bubun' +
                    questionnaire +
                    '.png'
                  "
                  [alt]="'あんけーと' + questionnaire"
                />
              </splide-slide>
            </splide>
          </div>
        </div>

        <div class="l-preschooler__main__service__schedule">
          <h3 class="l-preschooler__main__service__schedule__title">
            スケジュール
          </h3>
          <img
            class="l-preschooler__main__service__schedule__img c-pc"
            src="./../../../../assets/img/new/preschooler_schedule.png"
            alt=""
          />
          <img
            class="l-preschooler__main__service__schedule__img c-sp"
            src="./../../../../assets/img/new/preschooler_schedule.png"
            alt=""
          />
        </div>

        <div class="l-preschooler__main__service__contact-link">
          <app-contact-link></app-contact-link>
        </div>
      </div>
    </div>
    <app-floating-view></app-floating-view>
    <app-footer></app-footer>
  </div>
</div>
