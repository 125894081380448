<div class="l-wrapper">
  <app-header></app-header>
  <div class="l-contact">
    <app-title label="Contact" title="お問い合わせ"></app-title>
    <div class="l-contact__description">
      <span>下記フォームに必要事項をご入力の上、お問い合わせください。</span>
      <div class="l-contact__description__two-line">
        <span>お電話でのお問い合わせは</span>
        <span class="l-contact__description__two-line__tel">0798-61-5344</span>
        <span>にお願いいたします。（営業時間9：00～18：00）</span>
      </div>
    </div>

    <div class="l-contact__form">
      <form class="l-contact__content" [formGroup]="formGroup">
        <app-input
          label="お名前"
          [require]="true"
          placeholder=""
          formControlName="name"
        ></app-input>
        <app-input
          label="フリガナ"
          [require]="true"
          placeholder=""
          formControlName="kana"
        ></app-input>
        <app-input
          label="メールアドレス"
          [require]="true"
          placeholder=""
          formControlName="email"
        ></app-input>
        <app-input
          label="電話番号"
          [require]="true"
          placeholder=""
          formControlName="tel"
        ></app-input>

        <app-input
          label="郵便番号"
          [require]="true"
          placeholder=""
          formControlName="postcode"
          [maxValue]="8"
          (change)="onChangeNumber()"
        ></app-input>
        <app-selectbox
          formControlName="address"
          label="住所"
          [options]="prefectures"
          [selected]="formGroup.value.address"
        ></app-selectbox>
        <app-input
          label="住所1"
          [require]="true"
          placeholder=""
          formControlName="address1"
        ></app-input>
        <app-input
          label="住所2"
          [require]="true"
          placeholder=""
          formControlName="address2"
        ></app-input>
        <app-textarea
          label="お問い合わせ内容"
          [require]="true"
          placeholder=""
          formControlName="content"
        ></app-textarea>

        <button
          translate
          class="c-contact-button"
          type="button"
          (click)="onAccept()"
        >
          送信する
        </button>
      </form>
    </div>
  </div>
  <app-footer></app-footer>
</div>
