<div
  [style]="width"
  (window:resize)="onResize($event)"
  *ngIf="type === 'button'"
>
  <button
    [class]="class"
    [disabled]="disabled"
    [ngClass]="{ '-disable': disabled }"
    [type]="type"
  >
    {{ value }}
  </button>
</div>

<div
  (window:resize)="onResize($event)"
  *ngIf="type === 'management'"
  [class]="class"
>
  <img src="/assets/img/mypage/plus.png" />
  <p>{{ value }}</p>
</div>
