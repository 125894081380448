import { Injectable } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { HttpService } from '../../base/http.service';

@Injectable({
  providedIn: 'root',
})
export class PersonalConsentFormService {
  constructor(private httpService: HttpService) {}

  public createFormGroup(): UntypedFormGroup {
    return new UntypedFormGroup({
      name: new UntypedFormControl('', [Validators.required]),
      trainerName: new UntypedFormControl('松本 央真', [Validators.required]),
      email: new UntypedFormControl('', [Validators.required, Validators.email]),
      agree: new UntypedFormControl('', [Validators.required]),
    });
  }

  public zipcode(value: string): any {
    return this.httpService.get('zip/address/' + value);
  }
}
