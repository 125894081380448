import { Injectable } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';

@Injectable({
  providedIn: 'root',
})
export class SnackbarService {
  public messages: any = [];
  constructor() {}

  pushMessage(item: Partial<any>) {
    this.messages = [{ ...item, id: uuidv4() }];
    console.log(this.messages);
    setTimeout(() => {
      this.deleteMessage(this.messages.id);
    }, 5000);
  }

  deleteMessage(id: string) {
    // const index = messages.findIndex((message) => message.id === id);
    // if (index === -1) {
    //   return;
    // }
    // messages.splice(index, 1);
    // this.store.update({ messages });
  }

  pushValidationMessage() {
    this.pushMessage({
      type: 'error',
      message: 'エラーがあります。',
    });
  }
}
