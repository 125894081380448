import { Component, HostListener, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {
  @Input() class = '';
  @Input() value = '';
  @Input() type = 'button';
  @Input() width = 'width: 320px;height:80px';
  @Input() disabled = false;

  constructor() {}
  public innerWidth: number;
  private initWidth: string;

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    this.initWidth = this.width;
    this.size(window.innerWidth);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.size(window.innerWidth);
  }

  public size(width: number): void {
    if (width <= 560) {
      // this.width = 'width: 288px;height: 64px;';
    } else {
      this.width = this.initWidth;
    }
  }
}
