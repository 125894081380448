import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-top-content-kids',
  templateUrl: './top-content-kids.component.html',
  styleUrls: ['./top-content-kids.component.scss'],
})
export class TopContentKidsComponent implements OnInit {
  constructor() {}
  public list = [
    '・50m走の速度アップ',
    '・苦手な運動が減る',
    '・ボールの投げ方、取り方',
    '・鉄棒を克服',
  ];

  ngOnInit(): void {}
}
