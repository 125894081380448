import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-complete',
  templateUrl: './complete.component.html',
  styleUrls: ['./complete.component.scss']
})
export class CompleteComponent implements OnInit {
  public iconSrc = '';
  public title = '';
  public messeges = '';
  public src = '';
  public srcSp = '';
  public isLoading = false;

  constructor(private activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    this.title = this.activatedRoute.snapshot.queryParams.title;
    if (this.activatedRoute.snapshot.queryParams.messege) {
      this.messeges = this.activatedRoute.snapshot.queryParams.messege.split('\n\r');
    }
    if (this.activatedRoute.snapshot.queryParams.iconSrc) {
      this.iconSrc = this.activatedRoute.snapshot.queryParams.iconSrc;
    }
    if (this.activatedRoute.snapshot.queryParams.src && this.activatedRoute.snapshot.queryParams.srcSp) {
      this.src = this.activatedRoute.snapshot.queryParams.src;
      this.srcSp = this.activatedRoute.snapshot.queryParams.srcSp;
    }
  }
}
