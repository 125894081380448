import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-access',
  templateUrl: './access.component.html',
  styleUrls: ['./access.component.scss'],
})
export class AccessComponent implements OnInit {
  constructor(private meta: Meta, private title: Title) {}

  ngOnInit(): void {
    this.meta.addTag({
      name: 'description',
      content:
        'ダイナモBスタジオのアクセスページへようこそ。当スタジオへの訪問をご検討の方は、こちらの情報をご参照ください。私たちの住所は〒662-0964 兵庫県西宮市弓場町9-31です。このページには、お越しになる際の地図、公共交通機関の情報、駐車場の詳細など、便利なアクセス情報が満載です。ダイナモBスタジオでの素敵な体験が皆様をお待ちしています。',
    });
    this.title.setTitle('アクセス | ダイナモBスタジオ');
  }
}
