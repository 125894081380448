<div class="l-form">
  <label>
    <ng-container *ngIf="label">{{ label }}</ng-container>
  </label>
  <div class="c-require">
    <ng-container *ngIf="require">
      <span> 必須 </span>
    </ng-container>
  </div>
  <div class="l-form__content">
    <textarea
      #textarea
      [value]="control.value"
      [placeholder]="placeholder"
      (input)="onChange(textarea.value)"
      (blur)="onTouched()"
      required
      [ngClass]="{
        'c-error': control?.invalid && (control?.dirty || control?.touched)
      }"
    ></textarea>
    <div class="l-error">
      <div *ngIf="control?.invalid && (control?.dirty || control?.touched)">
        <div class="error-messege" *ngIf="control.errors?.email">
          有効なメールアドレスで入力してください。
        </div>
        <div class="error-messege" *ngIf="control?.errors?.required">
          お問い合わせ内容を入力してください。
        </div>
        <div *ngIf="control?.errors?.maxlength" class="error-messege">
          {{ maxValue }}文字以内で入力してください
        </div>
      </div>
    </div>
  </div>
</div>
