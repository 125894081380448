import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public isLoading = false;
  public error = {};
  public isLoginError = false;
  ngOnInit(): void {}

  public onLoading(isLoading: any) {
    this.isLoading = isLoading;
  }

  public onError(error: any) {
    console.log(error);
    this.isLoginError = true;
  }
}
