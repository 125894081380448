<div class="l-wrapper">
  <app-header></app-header>
  <div class="l-semi-personal">
    <img
      class="c-pc"
      src="/assets/img/new/semi-personal-main.png"
      alt="セミパーソナル"
    />
    <img
      class="c-sp"
      src="/assets/img/new/semi-personal-main-sp.png"
      alt="セミパーソナル"
    />
    <div class="l-semi-personal__main">
      <img
        class="c-sp"
        src="/assets/img/new/semi-personal-main-initial.png"
        alt="入会費用キャンペーン"
      />
      <!-- <img src="/assets/img/new/semi-personal-price.png" alt="業界最安値" /> -->
      <img
        src="/assets/img/new/semi-personal-free-trial.png"
        alt="セミパーソナル 無料体験"
      />
      <img
        class="c-pc"
        src="/assets/img/new/semi-personal-effect.png"
        alt="楽しい運動 短時間で運動 ボディメイク"
      />
    </div>

    <div class="l-semi-personal__method">
      <h3 class="l-semi-personal__method__title">
        突然ですが<br />あなたはどの方法で運動をしますか？
      </h3>
      <div class="l-semi-personal__method__content">
        <div>
          <img
            src="/assets/img/new/semi-personal-youtube.png"
            alt="Youtubeを見てトレーニングをしている"
          />
          <p class="l-semi-personal__method__content__text">Youtubeで運動</p>
        </div>
        <div>
          <img
            src="/assets/img/new/semi-personal-24h.png"
            alt="24hジム 24時間ジム"
          />
          <p class="l-semi-personal__method__content__text">24時間ジム</p>
        </div>
        <div>
          <img
            src="/assets/img/new/semi-personal-training.png"
            alt="パーソナルトレーニング"
          />
          <p class="l-semi-personal__method__content__text">
            パーソナル
            <br />トレーニング
          </p>
        </div>
      </div>
      <div class="l-semi-personal__method__flow">
        <img
          width="100%"
          src="/assets/img/new/semi-personal-flow.png"
          alt="多くの方はこんなことを経験してます"
          class="c-pc"
        />
        <img
          width="100%"
          src="/assets/img/new/semi-personal-flow-sp.png"
          alt="多くの方はこんなことを経験してます"
          class="c-sp"
        />
        <img
          width="100%"
          src="/assets/img/new/semi-personal-question.png"
          alt="料金が高すぎて続けれない | 一回痩せたけどリバウンドした | どんな運動したらいいかよく分からない |自分に甘えて継続できない | 周りの目が気になる | 身体を痛めてしまって続けれない"
        />
        <p class="l-semi-personal__method__flow__text">ダイナモBスタジオでは</p>
        <ul class="l-semi-personal__method__flow__list">
          <li>始めやすい</li>
          <li>継続できる</li>
          <li>効果を感じる</li>
          <li>料金が安い</li>
        </ul>
        <p class="l-semi-personal__method__flow__text">
          の4点を意識したセミパーソナルを提供しております
        </p>
        <img
          width="100%"
          class="l-semi-personal__schedule__img"
          src="./../../../../assets/img/new/semi-personal-hikaku.png"
          alt=""
        />
      </div>
    </div>

    <div class="l-semi-personal__select">
      <h3 class="l-semi-personal__select__title">
        体幹や足腰をはじめ<br />全身をトレーニングすることで
      </h3>
      <div class="l-semi-personal__select__content">
        <div>
          <img
            src="/assets/img/new/semi-personal-effect_1.png"
            alt="体力づくり ダイエット 運動不足解消"
          />
          <ul class="l-semi-personal__select__content__list">
            <li>体力づくり</li>
            <li>ダイエット</li>
            <li>運動不足解消</li>
          </ul>
        </div>
        <div class="l-semi-personal__select__list">
          <img
            src="/assets/img/new/semi-personal-effect_2.png"
            alt="健康的な身体 姿勢改善 骨盤調整 全身トレーニング"
          />
          <ul class="l-semi-personal__select__content__list">
            <li>健康的な身体</li>
            <li>姿勢改善</li>
            <li>骨盤調整</li>
            <li>全身トレーニング</li>
          </ul>
        </div>
      </div>
    </div>

    <div class="l-semi-personal__effect">
      <h3 class="l-semi-personal__effect__title">
        ダイナモBスタジオが選ばれる理由
      </h3>
      <div class="l-semi-personal__effect__content">
        <div>
          <img
            src="/assets/img/new/safety-icon.png"
            alt="体力づくり ダイエット 運動不足解消"
          />
          <p class="l-semi-personal__effect__content__text">
            安心・安全<br />トレーニング
          </p>
        </div>
        <div>
          <img
            src="/assets/img/new/menu-icon.png"
            alt="体力づくり ダイエット 運動不足解消"
          />
          <p class="l-semi-personal__effect__content__text">
            楽しい <br />トレーニングが多数
          </p>
        </div>
        <div>
          <img
            src="/assets/img/new/money-icon.png"
            alt="体力づくり ダイエット 運動不足解消"
          />
          <p class="l-semi-personal__effect__content__text">
            業界相場より <br />安い
          </p>
        </div>
        <div>
          <img
            src="/assets/img/new/day-and-night-icon.png"
            alt="体力づくり ダイエット 運動不足解消"
          />
          <p class="l-semi-personal__effect__content__text">
            通いやすい <br />朝と夜で実施
          </p>
        </div>
      </div>
    </div>

    <div class="l-semi-personal__service">
      <h3 class="l-semi-personal__service__title">プログラムについて</h3>
      <div class="l-semi-personal__service__description">
        <span class="l-semi-personal__service__description__number">1</span>
        <h3 class="l-semi-personal__service__description__title">
          最先端のTRXとアニマルフローで体幹強化
        </h3>
        <img src="./../../../../assets/img/new/semi-personal_trx.png" alt="" />
        <div class="l-semi-personal__service__description__text">
          <p>
            ベースとして導入しているのが「TRX」と「アニマルフロー」という世界中で人気の最先端トレーニング。筋力やバランス、体幹強化はもちろん、柔軟性や可動性の向上も目指せます。
            そのため、姿勢改善をはじめ、目的に合わせて運動を行う事が可能となります。
          </p>
        </div>
      </div>
      <div class="l-semi-personal__service__description">
        <span class="l-semi-personal__service__description__number">2</span>
        <h3 class="l-semi-personal__service__description__title">
          足腰を重点的にトレーニングでQOL向上
        </h3>
        <img src="./../../../../assets/img/new/semi-personal_legs.png" alt="" />
        <div class="l-semi-personal__service__description__text">
          <p>
            足腰を重点的にトレーニングすることにより、『基礎代謝の向上』、『姿勢の改善』、『怪我の予防』、『バランス能力の向上』など、多岐にわたる能力向上が期待できます。
          </p>
        </div>
      </div>

      <div class="l-semi-personal__service__description">
        <span class="l-semi-personal__service__description__number">3 </span>
        <h3 class="l-semi-personal__service__description__title">完全予約制</h3>
        <img
          src="./../../../../assets/img/new/semi-personal_contact.jpg"
          alt=""
        />
        <div class="l-semi-personal__service__description__text">
          <p>
            最大4名様までのセミパーソナルレッスンを提供しております。
            人数が多すぎると、「十分な指導を受けられない」「効果を実感しにくい」といった問題が生じることがあります。
            そのため、私たちは完全予約制を採用し、質の高いサービスを提供しています。
            また、ご利用いただいたお客様には、次回の予約をその場でお取りいただくことが可能です。
          </p>
        </div>
      </div>
    </div>

    <div class="l-semi-personal__programme">
      <h3 class="l-semi-personal__programme__title">プログラム作成者</h3>
      <div class="l-semi-personal__programme__description">
        <div class="l-semi-personal__programme__description__profile">
          <img
            src="./../../../../assets/img/new/satou-kousuke.png"
            alt="佐藤 康介"
          />
          <div class="l-semi-personal__programme__description__profile__name">
            <span>Kosuke Satou</span>
            <!-- <a href="https://twitter.com/mirai_base_ceo" target="_blank">
              <img
                src="./../../../../assets/img/new/twitter_green.png"
                alt="佐藤 康介のツイッター"
              />
            </a> -->
            <a href="https://www.instagram.com/pt_mirai_base/" target="_blank">
              <img
                src="./../../../../assets/img/new/instagram_green.png"
                alt="佐藤 康介のインスタグラム"
              />
            </a>
          </div>
          <ul>
            <li>NESTA(全米エクササイズ&スポーツトレーナー協会)</li>
            <li>公認パーソナルトレーナー</li>
            <li>パーソナルトレーナー公認講師</li>
            <li>アニマルフロー 認定インストラクター</li>
            <!-- <li>100トレ認定インストレクター</li> -->
          </ul>
        </div>
        <div class="l-semi-personal__programme__description__career">
          <span class="l-semi-personal__programme__description__career__title">
            <p
              class="l-semi-personal__programme__description__career__title__japan"
            >
              キャリア
            </p>
            <p
              class="l-semi-personal__programme__description__career__title__en"
            >
              Career
            </p>
          </span>

          <img src="./../../../../assets/img/new/kids-line.png" alt="" />
          <ul class="l-semi-personal__programme__description__career__list">
            <li>兵庫県にて出張パーソナルトレーニングの「みらいBASE 代表」</li>
            <li>元ゴールドジム マネージャー</li>
            <li>パーソナルトレーナー</li>
            <li>メディカルフィットネスアドバイザー</li>
          </ul>
        </div>
      </div>
    </div>

    <div class="l-semi-personal__flow">
      <div class="l-semi-personal__flow__content1">
        <h2 class="l-semi-personal__flow__content1__title">
          最初のトレーニングの流れ
        </h2>
      </div>
      <div class="l-semi-personal__flow__content2">
        <img
          src="./../../../../assets/img/new/kids-ninthview1.png"
          alt="電話、Web、LINEにて連絡があり次第、予約を取らせて頂きます。"
        />
        <img
          src="./../../../../assets/img/new/kids-top-ninthview-right.png"
          class="--right c-pc"
        />
        <img
          src="./../../../../assets/img/new/kids-top-ninthview-bottom.png"
          class="--right c-sp"
        />
        <img
          src="./../../../../assets/img/new/personal-ninthview2.png"
          alt="簡単な質問用紙に記入をお願いしております。運動できる服装と水分の用意をお願い致します。
          また、室内シューズは任意となります。裸足でも問題ございません。"
        />
        <img
          src="./../../../../assets/img/new/kids-top-ninthview-right.png"
          class="--right c-pc"
        />
        <img
          src="./../../../../assets/img/new/kids-top-ninthview-bottom.png"
          class="--right c-sp"
        />
        <img
          src="./../../../../assets/img/new/personal-ninthview3.png"
          alt="60分のなかでカウンセリングをしっかりと行い目標やご要望をお聞きします。
          そしてお客様個人に合わせたトレーニングを体験いただきます。"
        />
        <img
          src="./../../../../assets/img/new/personal-ninthview4.png"
          alt="スタッフが全員にお声掛けさせていただきます。入会希望の方はこちらで入会手続きをさせて頂きます。"
        />
        <img
          src="./../../../../assets/img/new/kids-top-ninthview-right.png"
          class="--right c-pc"
        />
        <img
          src="./../../../../assets/img/new/kids-top-ninthview-bottom.png"
          class="--right c-sp"
        />
        <img
          src="./../../../../assets/img/new/kids-ninthview5.png"
          alt="簡単なアンケートを記入して頂き終了となります。"
        />
      </div>
    </div>

    <div class="l-semi-personal__price">
      <h2 class="l-semi-personal__price__title">料金について</h2>
      <div class="l-semi-personal__price__main">
        <div>料金プラン</div>
        <div>料金</div>

        <div>月4回プラン</div>
        <div>12,000円（税込）</div>

        <div>追加レッスンの場合</div>
        <div>3,000円 （税込）</div>
        <!--
          <div>週2回プラン</div>
          <div>14,400円 （税込）</div>

          <div>週3回プラン</div>
          <div>22,000円 （税込）</div> -->
      </div>
      <p class="l-semi-personal__price__text">
        ※セミパーソナルとなるため定員4名までとなります<br />
        1名様の場合はパーソナルとなります
      </p>
    </div>

    <div class="l-semi-personal__schedule">
      <h3 class="l-semi-personal__schedule__title">スケジュール</h3>
      <img
        class="l-semi-personal__schedule__img c-pc"
        src="./../../../../assets/img/new/semi-personal_schedule.png"
        alt=""
      />
      <img
        class="l-semi-personal__schedule__img c-sp"
        src="./../../../../assets/img/new/semi-personal_schedule.png"
        alt=""
      />
    </div>

    <div class="l-semi-personal__voice">
      <h3 class="l-semi-personal__voice__title">お客様のお声</h3>
      <div class="l-semi-personal__voice__main">
        <div class="l-semi-personal__voice__main__content">
          <div class="l-semi-personal__voice__main__header">
            <img src="./../../../../assets/img/new/worman-icon.png" />
            <div class="l-semi-personal__voice__main__header__title">
              <h3 class="l-semi-personal__voice__main__header__title__text">
                無理なく楽しく続けられます。
              </h3>
              <p class="l-semi-personal__voice__main__header__title__status">
                40代　女性
              </p>
            </div>
          </div>
          <div class="l-semi-personal__voice__main__description">
            <p>
              とても優しく丁寧にご指導くださる信頼できる先生です。前屈みが多い女性の身体を、細かくコアな筋肉まで考えて、美容や普段の家事育児や仕事がスムーズになるように親身になって考え寄り添って下さるので安心して身を委ねることができてきます。コロナでの増量、ライフスタイルの変化により、40歳過ぎて日々しんどさを感じでいるので、また7月から身体づくりをサポートしていただくべく月に２回のパーソナルトレーニング、よろしくお願いいたします❕紳士で誠実な佐藤先生、おススメです
            </p>
          </div>
        </div>
        <div class="l-semi-personal__voice__main__content">
          <div class="l-semi-personal__voice__main__header">
            <img src="./../../../../assets/img/new/worman-icon.png" />
            <div class="l-semi-personal__voice__main__header__title">
              <h3 class="l-semi-personal__voice__main__header__title__text">
                運動を楽しく続けられます。
              </h3>
              <p class="l-semi-personal__voice__main__header__title__status">
                30代　女性
              </p>
            </div>
          </div>
          <div class="l-semi-personal__voice__main__description">
            <p>
              不安点や疑問点も質問に対して丁寧に指導貰えます！優しい口調で安心感もありますので女性の方でも安心して受けることができますよ！
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="l-semi-personal__contact-link">
      <app-contact-link label="semi-pesonal"></app-contact-link>
    </div>
    <app-floating-view></app-floating-view>
    <app-footer></app-footer>
  </div>
</div>
