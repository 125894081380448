import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-tab-layout',
  templateUrl: './tab-layout.component.html',
  styleUrls: ['./tab-layout.component.scss'],
})
export class TabLayoutComponent implements OnInit {
  constructor() {}
  @Output() num = new EventEmitter<any>();
  @Input() type = '';
  @Input() number = 0;

  ngOnInit(): void {}

  onTab(number: number) {
    this.number = number;
    this.num.emit(this.number);
  }
}
