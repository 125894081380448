<app-top-content-link
  link="/kids-experience"
  title="子ども用の運動"
  type="blue"
  backgroundcolor="#38B1FF"
  [list]="list"
  img="./../../../../assets/img/new/top-content-kids.png"
  imgSp="./../../../../assets/img/new/top-content-kids-sp.png"
>
</app-top-content-link>
