import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-top-content-adult',
  templateUrl: './top-content-adult.component.html',
  styleUrls: ['./top-content-adult.component.scss'],
})
export class TopContentAdultComponent implements OnInit {
  constructor() {}
  public list = [
    '・姿勢改善',
    '・足腰の痛み改善',
    '・体を引き締める',
    '・体力アップ',
    '・怪我をしにくい体になる',
  ];
  ngOnInit(): void {}
}
