<div class="l-wrapper">
  <app-header></app-header>
  <div class="l-price">
    <img src="/assets/img/new/price-main.png" alt="料金表" class="c-pc" />
    <img src="/assets/img/new/price-main-sp.png" alt="料金表" class="c-sp" />
    <div class="l-price__campaign">
      <app-campaign-view></app-campaign-view>
    </div>
    <div class="l-price__plan">
      <app-price-plan></app-price-plan>
    </div>
    <div class="l-price__method">
      <h3 class="l-price__method__title">支払方法</h3>
      <p class="l-price__method__description">
        クレジットカード（Visa、Mastercard、jcb、Amex） が使用可能です。
      </p>
    </div>
    <!-- <app-question-view id=""></app-question-view> -->
    <div class="l-price__contact-link">
      <app-contact-link></app-contact-link>
    </div>
  </div>
  <app-footer></app-footer>
</div>
