import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss'],
})
export class AboutComponent implements OnInit {
  constructor(private meta: Meta, private title: Title) {}

  ngOnInit(): void {
    this.meta.addTag({
      name: 'description',
      content:
        'ダイナモBスタジオの会社概要ページへようこそ。当ページでは、ダイナモBスタジオを運営する株式会社growth developの企業情報をご紹介しています。私たちのビジョン、ミッション、サービス内容、そしてチームの情報など、会社に関する詳細情報を提供しています。ダイナモBスタジオの背後にある企業としての取り組みや、私たちが大切にしている価値観をお知りいただけます。',
    });
    this.title.setTitle('会社概要 | ダイナモBスタジオ');
  }
}
