<div class="l-wrapper">
  <app-header></app-header>
  <div class="l-access">
    <div class="l-access__title">
      <app-title label="Access" title="アクセス"></app-title>
    </div>
    <div class="l-access__main">
      <h3 class="l-access__main__title">所在地</h3>
      <div class="l-access__main__content">
        <div class="l-access__main__content__content2">
          <img
            class="c-pc"
            src="./../../../../assets/img/new/goal.jpg"
            alt="ダイナモBスタジオ"
            width="482px"
          />
          <img
            class="c-sp"
            src="./../../../../assets/img/new/goal.jpg"
            alt="ダイナモBスタジオ"
          />
          <!-- <img
            class="c-pc"
            src="./../../../../assets/img/new/place.jpg"
            alt="ダイナモBスタジオ"
            width="482px"
          />
          <img
            class="c-sp"
            src="./../../../../assets/img/new/place.jpg"
            alt="ダイナモBスタジオ"
          /> -->
          <div class="l-access__main__content__content2__description">
            <div>〒662-0964 <br />兵庫県西宮市弓場町９−３１</div>
            <div>
              <img src="./../../../../assets/img/place-tel.png" />
              0798-61-5344
            </div>
            <div>
              <img src="./../../../../assets/img/place-train.png" />
              JRさくら夙川駅から徒歩10分
            </div>
            <div>
              <img src="./../../../../assets/img/place-train.png" />
              阪神香櫨園駅から徒歩10分
            </div>
            <div>
              <img src="./../../../../assets/img/place-train.png" />
              阪神打出駅から徒歩10分
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="l-access__map">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d487.396144065718!2d135.32096379902288!3d34.73550181221216!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6000f2e648280945%3A0x1245bb3aeafd0b7a!2z44CSNjYyLTA5NjQg5YW15bqr55yM6KW_5a6u5biC5byT5aC055S677yZ4oiS77yT77yR!5e0!3m2!1sja!2sjp!4v1686796923940!5m2!1sja!2sjp"
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>

    <div class="l-access__main">
      <h3 class="l-access__main__title">駐車場について</h3>
      <div class="l-access__main__content">
        <div class="l-access__main__content__content2">
          <img
            src="./../../../../assets/img/new/parking.png"
            alt="ダイナモBスタジオ"
            width="482px"
            class="c-pc"
          />
          <img
            src="./../../../../assets/img/new/parking-sp.png"
            alt="ダイナモBスタジオ"
            class="c-sp"
          />
          <div class="l-access__main__content__content2__description">
            <p>
              当施設には駐車場はございません<br />
              送迎の際は隣にあるパーキングのご利用をお願いしております。<br />
              また、以下は周辺の駐車場となります。
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="l-access__map">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d1639.400746346509!2d135.31975820893388!3d34.73539772625642!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1z6aeQ6LuK5aC0!5e0!3m2!1sja!2sjp!4v1684688768061!5m2!1sja!2sjp"
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>

    <!-- <div class="l-access__main">
      <h3 class="l-access__main__title">阪神打出駅から</h3>
      <div class="l-access__main__list">
        <div class="l-access__main__list__content">
          <span class="l-access__main__list__content__number">1</span>
          <img src="./../../../../assets/img/new/utchide-1.png" />
          <p class="l-access__main__list__content__text">阪神打出駅です</p>
        </div>
        <div class="l-access__main__list__content">
          <span class="l-access__main__list__content__number">2</span>
          <img src="./../../../../assets/img/new/utchide-2.png" />
          <p class="l-access__main__list__content__text">
            セブンイレブンをまっすぐ進んでください
          </p>
        </div>
        <div class="l-access__main__list__content">
          <span class="l-access__main__list__content__number">3</span>
          <img src="./../../../../assets/img/new/utchide-3.png" />
          <p class="l-access__main__list__content__text">
            交差点を道なりにまっすぐ進んでください
          </p>
        </div>
        <div class="l-access__main__list__content">
          <span class="l-access__main__list__content__number">4</span>
          <img src="./../../../../assets/img/new/utchide-4.png" />
          <p class="l-access__main__list__content__text">
            2号線の交差点を右折してください
          </p>
        </div>
        <div class="l-access__main__list__content">
          <span class="l-access__main__list__content__number">5</span>
          <img src="./../../../../assets/img/new/utchide-5.png" />
          <p class="l-access__main__list__content__text">
            2号線の歯科をまっすぐすすんでください
          </p>
        </div>
        <div class="l-access__main__list__content">
          <span class="l-access__main__list__content__number">6</span>
          <img src="./../../../../assets/img/new/utchide-6.png" />
          <p class="l-access__main__list__content__text">
            マクドナルドもまっすぐ進んでください
          </p>
        </div>
        <div class="l-access__main__list__content">
          <span class="l-access__main__list__content__number">7</span>
          <img src="./../../../../assets/img/new/utchide-7.png" />
          <p class="l-access__main__list__content__text">
            ガソリンスタンドをまっすぐ進んでください
          </p>
        </div>
        <div class="l-access__main__list__content">
          <span class="l-access__main__list__content__number">8</span>
          <img src="./../../../../assets/img/new/utchide-8.png" />
          <p class="l-access__main__list__content__text">
            2号線をまっすぐ行ったところのフォルクスをまっすぐ進んでください
          </p>
        </div>
        <div class="l-access__main__list__content">
          <span class="l-access__main__list__content__number">9</span>
          <img src="./../../../../assets/img/new/goal.jpg" />
          <p class="l-access__main__list__content__text">
            こちらが当施設となります。
          </p>
        </div>
      </div>
    </div>

    <div class="l-access__main">
      <h3 class="l-access__main__title">JRさくら夙川駅</h3>
      <div class="l-access__main__list">
        <div class="l-access__main__list__content">
          <span class="l-access__main__list__content__number">1</span>
          <img src="./../../../../assets/img/new/sakura-syukugawa-1.png" />
          <p class="l-access__main__list__content__text">
            JRさくら夙川駅です。
          </p>
        </div>
        <div class="l-access__main__list__content">
          <span class="l-access__main__list__content__number">2</span>
          <img src="./../../../../assets/img/new/sakura-syukugawa-2.png" />
          <p class="l-access__main__list__content__text">
            さくら夙川から左手に見えるローソンの道沿いへ
          </p>
        </div>
        <div class="l-access__main__list__content">
          <span class="l-access__main__list__content__number">3</span>
          <img src="./../../../../assets/img/new/sakura-syukugawa-3.png" />
          <p class="l-access__main__list__content__text">
            信号をわたり車屋を右手に進んでください。
          </p>
        </div>
        <div class="l-access__main__list__content">
          <span class="l-access__main__list__content__number">4</span>
          <img src="./../../../../assets/img/new/sakura-syukugawa-4.png" />
          <p class="l-access__main__list__content__text">
            夙川をまっすぐ進んでください。
          </p>
        </div>
        <div class="l-access__main__list__content">
          <span class="l-access__main__list__content__number">5</span>
          <img src="./../../../../assets/img/new/sakura-syukugawa-5.png" />
          <p class="l-access__main__list__content__text">
            バイク屋さんを道なりに進んでください。
          </p>
        </div>
        <div class="l-access__main__list__content">
          <span class="l-access__main__list__content__number">6</span>
          <img src="./../../../../assets/img/new/goal.jpg" />
          <p class="l-access__main__list__content__text">
            こちらが当施設となります。
          </p>
        </div>
      </div>
    </div> -->

    <!-- <div class="l-access__main">
      <h3 class="l-access__main__title">阪神香櫨園駅から</h3>
    </div> -->
  </div>
  <app-footer></app-footer>
</div>
