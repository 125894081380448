import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-facility',
  templateUrl: './facility.component.html',
  styleUrls: ['./facility.component.scss'],
})
export class FacilityComponent implements OnInit {
  constructor(private meta: Meta, private title: Title) {}

  ngOnInit(): void {
    this.meta.addTag({
      name: 'description',
      content:
        'ダイナモBスタジオの設備紹介ページへようこそ。当ページでは、私たちの最先端のトレーニング設備、快適で清潔な施設、そして機能的なスペースの概要をご覧いただけます。各設備の特徴や利用方法に加え、トレーニング環境を最大限に活用するためのヒントも提供しています。ダイナモBスタジオでのトレーニング体験をより豊かにするための情報が満載です。',
    });
    this.title.setTitle('設備紹介 | ダイナモBスタジオ');
  }
}
