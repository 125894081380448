import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-top-kids',
  templateUrl: './top-kids.component.html',
  styleUrls: ['./top-kids.component.scss'],
})
export class TopKidsComponent implements OnInit {
  constructor(private meta: Meta, private title: Title) {}

  ngOnInit(): void {
    this.meta.addTag({
      name: 'description',
      content:
        '毎日の体育が楽しい！と感じられるように！ダイナモBスタジオは、運動の楽しさや基礎を教える運動教室です。具体的には足が速くなるかけっこ教室、全身運動の体幹を鍛える事ができる施設となっております。',
    });
    this.title.setTitle(
      'かけっこ、体幹教室 | ダイナモBスタジオ | 西宮・芦屋市'
    );
  }
}
