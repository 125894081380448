<!-- <app-tab-layout (num)="onNumber($event)" type="notAll" [number]="number"> -->
<!-- <div class="l-price-plan" *ngIf="number === 1">
    <div>料金プラン(1回)</div>
    <div>料金</div>

    <div>1人参加</div>
    <div>8,800円/人（税込）</div>

    <div>2人参加</div>
    <div>7,700円/人（税込）</div>

    <div>3人参加</div>
    <div>6,600円/人（税込）</div>
  </div>

  <div class="l-price-plan" *ngIf="number === 2">
    <div>料金プラン</div>
    <div>料金</div>

    <div>週1回プラン</div>
    <div>5,500円（税込）</div>

    <div>週2回プラン</div>
    <div>9,900円 （税込）</div>

    <div>週3回プラン</div>
    <div>14,000円 （税込）</div>
  </div> -->
<div class="l-price-plan__pattern2">
  <div class="l-price-plan__pattern2__title">
    <h3>セミパーソナル</h3>
  </div>
  <div class="l-price-plan__pattern2__main">
    <div>料金プラン(1回)</div>
    <div>料金</div>

    <div>月4回プラン</div>
    <div>12,000円</div>
  </div>
  <p class="l-price-plan__year">
    ※当月入会の場合は日割りで計算とさせて頂きます
  </p>

  <div class="l-price-plan__pattern2__title">
    <h3>小学生向けプラン</h3>
  </div>
  <div class="l-price-plan__pattern2__main">
    <div>料金プラン</div>
    <div>料金</div>

    <div>週1回プラン</div>
    <div>7,700円（税込）</div>

    <div>週2回プラン</div>
    <div>14,400円 （税込）</div>

    <div>週3回プラン</div>
    <div>22,000円 （税込）</div>
  </div>

  <div class="l-price-plan__pattern2__title">
    <h3>未就学児（年中・年長）向けプラン</h3>
  </div>
  <div class="l-price-plan__pattern2__main">
    <div>料金プラン</div>
    <div>料金</div>

    <div>週1回プラン</div>
    <div>6,600円（税込）</div>

    <div>週2回プラン</div>
    <div>12,800円 （税込）</div>
  </div>
</div>

<p class="l-price-plan__year">
  ※お子様の場合スポーツ保険を年800円頂いております。<br />年度で切り替わります。
</p>
<!-- </app-tab-layout> -->
