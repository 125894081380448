import { Component, Input, OnInit } from '@angular/core';
declare var gtag: any;

@Component({
  selector: 'app-contact-link',
  templateUrl: './contact-link.component.html',
  styleUrls: ['./contact-link.component.scss'],
})
export class ContactLinkComponent implements OnInit {
  constructor() {}
  @Input() label = '';

  ngOnInit(): void {}

  onConversion(e: any) {
    // Adwords Conversion
    console.log('eeeeeeeeeee22');
    console.log(e);
    gtag('event', 'conversion', {
      send_to: 'AW-11238398507/YcPECKuPmusYEKu08e4p',
    });
  }
}
