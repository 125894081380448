<div class="l-kids-service">
  <h3 class="l-kids-service__title">サービスの特徴</h3>
  <div class="l-kids-service__description">
    <span class="l-kids-service__description__number">1</span>
    <h3 class="l-kids-service__description__title">
      足が速くなる運動プログラム
    </h3>
    <img src="./../../../../assets/img/new/kids-service-1.png" alt="" />
    <div class="l-kids-service__description__text">
      <p>
        元陸上選手で現在指導者をしている方にプログラムを作成していただいております。
      </p>
      <p>
        実際にこのプログラムを受講された方は５０ｍ走のタイムが伸びております。
      </p>
      <p>
        足が速くなりたいと思う方は多くても、実際に足を速くするための「走り方」と
        「トレーニング」方法を知らない方が多いです。
      </p>
      <p>
        そのため、知識と経験に基づく結果の出るプログラムを自信を持って提供していま
        す。
      </p>
    </div>
  </div>

  <div class="l-kids-service__description">
    <span class="l-kids-service__description__number">2</span>
    <h3 class="l-kids-service__description__title">
      正しい身体の使い方を学べる
    </h3>
    <img src="./../../../../assets/img/new/kids-service-4.jpeg" alt="" />
    <div class="l-kids-service__description__text">
      <p>
        ダイナモBスタジオでは、準備運動からしっかりと指導をさせて頂いております。
        正しい身体の使い方を指導するために、少人数で1クラス最大10名で行います。
      </p>
      <p>
        考え方が理解できるようになると「投げる」「ジャンプ」といった原理がわかるようになり、
        野球、バスケ、サッカーといったスポーツから空手や柔道などの格闘技でも活躍する事が出来ます。
        特に最初は身体の使い方を知るために、「走る」の基礎が理解してもらうようにしております。
      </p>
    </div>
  </div>

  <div class="l-kids-service__description">
    <span class="l-kids-service__description__number">3</span>
    <h3 class="l-kids-service__description__title">
      最先端のトレーニングを常に取り入れている
    </h3>
    <img src="./../../../../assets/img/new/kids-service-2.jpeg" alt="" />
    <div class="l-kids-service__description__text">
      <p>
        最先端のトレーニングTRXとアニマルフローで体幹と全身の筋肉を鍛えることが
        できます。どんな運動にも体幹と全身の筋肉は使用するため、トレーニングをし
        ておいて損することはございまいません。
      </p>
      <p>
        アニマルフローというエクササイズは、名前の通り、動物の動きを取り入れたエ
        クササイズとなります。特に「筋力・バランス力・柔軟性・体幹」といった部分
        を鍛えることができます。
      </p>
    </div>
  </div>

  <div class="l-kids-service__description">
    <span class="l-kids-service__description__number">4</span>
    <h3 class="l-kids-service__description__title">体力測定シート</h3>
    <img src="./../../../../assets/img/new/kids-service-3.png" alt="" />
    <div class="l-kids-service__description__text">
      <p>進捗を確認するために、1〜3カ月ごとに体力測定を行います。</p>
      <p>
        測定することで平均よりも下回っていないか、お子様のスキルが順調に伸びて
        いるのかをチェックすることができます。
      </p>
      <p>お子様によっては大幅に成長する子もいらっしゃいます。</p>
      <p>
        成長を数値として見ることができるため、「また頑張ろう！」とやる気アップ
        にも繋がります。
      </p>
    </div>
  </div>

  <div class="l-kids-service__description">
    <span class="l-kids-service__description__number">5</span>
    <h3 class="l-kids-service__description__title">少数で運動を行う</h3>
    <img src="./../../../../assets/img/new/kids-service-5.jpg" alt="" />
    <div class="l-kids-service__description__text">
      <p>
        指導にあたっては、大人数では対応が難しい部分があるため、効果的かつ丁寧な指導を心がけています。<br />
        このため、小学生のクラスでは最大10人、未就学児のクラスでは最大6人までという人数制限を設け、それぞれのニーズに合わせたプログラムを提供しております。<br />
        丁寧な指導を行うためのこの人数制限にご理解いただけますと幸いです。
      </p>
    </div>
  </div>
</div>
