import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-kids-service',
  templateUrl: './kids-service.component.html',
  styleUrls: ['./kids-service.component.scss'],
})
export class KidsServiceComponent implements OnInit {
  @Input() type = '';

  constructor() {}

  ngOnInit(): void {}
}
