import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss'],
})
export class FaqComponent implements OnInit {
  constructor(private meta: Meta, private title: Title) {}

  ngOnInit(): void {
    this.meta.addTag({
      name: 'description',
      content:
        'ダイナモBスタジオに関するよくある質問へようこそ。こちらでは、当スタジオのサービス、トレーニングプログラム、スケジュール、料金体系などについてのお客様からの一般的な疑問に対して、明確で役立つ回答を提供しています。お問い合わせ内容に関する迅速で詳細な情報をお求めの際は、こちらのセクションをご参照ください。',
    });
    this.title.setTitle('よくある質問 | ダイナモBスタジオ');
  }
}
