import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-recruit',
  templateUrl: './recruit.component.html',
  styleUrls: ['./recruit.component.scss'],
})
export class RecruitComponent implements OnInit {
  constructor(private meta: Meta, private title: Title) {}

  ngOnInit(): void {
    this.meta.addTag({
      name: 'description',
      content:
        '2023年夏ごろにオープン予定の『ダイナモBスタジオ』運動指導トレーナーとして、子どもと高齢者を中心とした指導をお任せします。また店舗運営スタッフとしてお客様の手続き、清掃、販促等をしていただきます。ダイナモBスタジオとは 主に小学生・高齢者を対象とした運動教室です。小学生には『かけっこ』が速くなるプログラムを提供。運動が苦手で体育が嫌いな子、他のスポーツをしていて今後活躍したい子の基礎を作ります。また自重で体を動かすアメリカ発祥の『アニマルフロー』を導入。両手両足を使い、自分の体をしっかり動かすことで、心身ともにコントロールできるように、必ずアニマルフローをメニューに組んでいます。高齢者には100歳まで元気に、がコンセプトの『100トレ』体操や、『アニマルフロー』を導入する事で、いつまでも健康な体をキープできるようになってます。多彩なキャリアをご用意!',
    });
    this.title.setTitle('採用情報 | ダイナモBスタジオ');
  }
}
