<div class="l-campaign">
  <div class="l-campaign__img">
    <a href="/kids-experience">
      <img
        src="./../../../../assets/img/new/campaign-free.png"
        alt="無料体験会実施中"
        class="c-pc" />
      <img
        width="340px"
        src="./../../../../assets/img/new/campaign-free-sp.png"
        alt="無料体験会実施中"
        class="c-sp"
    /></a>

    <img
      src="./../../../../assets/img/new/campaign-admission.png"
      alt="入会金無料 今だけ0円"
      class="c-pc"
    />
    <img
      src="./../../../../assets/img/new/campaign-admission-sp.png"
      alt="入会金無料 今だけ0円"
      class="c-sp"
    />

    <img
      src="./../../../../assets/img/new/campaign-friend.png"
      alt="お友達紹介キャンペーン 入会翌月の会費から500円引き"
      class="c-pc"
    />
    <img
      src="./../../../../assets/img/new/campaign-friend-sp.png"
      alt="お友達紹介キャンペーン 入会翌月の会費から500円引き"
      class="c-sp"
    />

    <!-- <img
      src="./../../../../assets/img/new/campaign-tour.png"
      alt="無料見学会開催|8月19日24日25日26日 10:00~17:00"
      class="c-pc"
    />
    <img
      src="./../../../../assets/img/new/campaign-tour.png"
      alt="無料見学会開催|8月19日24日25日26日 10:00~17:00"
      class="c-sp"
      width="300px"
      height="250px"
    /> -->
  </div>
  <div class="l-campaign__button" *ngIf="isPlan">
    <a class="l-campaign__button__link" href="/price">
      <p>詳しい料金プランはこちら</p>
      <img
        _ngcontent-cxw-c54=""
        src="/assets/img/new/right-icon.png"
        alt="矢印"
      />
    </a>
  </div>
</div>
