<form>
  <div class="l-header">
    <span>
      <h1 class="l-header__logo">
        <a href="/">
          <img
            alt="ダイナモBスタジオ"
            src="./../../../../assets/img/logo.png"
            class="c-pc" />
          <img
            alt="ダイナモBスタジオ"
            src="./../../../../assets/img/logo-sp.png"
            class="c-sp"
        /></a>
      </h1>
    </span>
    <div class="l-header__menu">
      <ul class="l-header__list">
        <li class="l-header__list__access">
          <a href="/kids-experience">
            <img
              alt="小学生向け|無料体験"
              src="./../../../../assets/img/new/contact-sp-icon.png"
              width="60px"
              height="60px"
          /></a>
          <a href="/access">
            <img
              alt="アクセス"
              src="./../../../../assets/img/new/access-icon.png"
              class=""
          /></a>
          <a href="/contact">
            <div class="l-header__list__access__button --orange-button">
              <img src="./../../../../assets/img/new/mail-icon.png" />
              お問い合わせ
            </div>
          </a>
        </li>
      </ul>
      <ul class="l-header__list">
        <li>
          <a href="/semi-personal">
            <p translate>セミパーソナル</p>
          </a>
        </li>
        <!-- <li>
          <a href="/top-kids">
            <p translate>小学生向け</p>
          </a>
        </li>
        <li>
          <a href="/preschooler">
            <p translate>未就学児向け</p>
          </a>
        </li> -->
        <li>
          <a href="/price">
            <p translate>料金プラン</p>
          </a>
        </li>
        <li>
          <a href="/schedule">
            <p translate>スケジュール</p>
          </a>
        </li>
        <li>
          <a href="/facility">
            <p translate>設備紹介</p>
          </a>
        </li>
        <li>
          <a href="/blog-list">
            <p translate>ブログ</p>
          </a>
        </li>
        <li>
          <a href="/voice">
            <p translate>お客様の声</p>
          </a>
        </li>
        <li>
          <a href="/faq">
            <p translate>よくある質問</p>
          </a>
        </li>
        <li>
          <a href="https://twitter.com/dynamo_b_studio" target="_blank">
            <img
              class="l-header__list__sns"
              alt="ダイナモBスタジオのtwitter"
              src="./../../../../assets/img/twitter.png"
            />
          </a>
        </li>
        <li>
          <a href="https://www.instagram.com/dynamo_b_studio/" target="_blank">
            <img
              class="l-header__list__sns"
              alt="ダイナモBスタジオのInstagram"
              src="./../../../../assets/img/instagram.png"
              translate
            />
          </a>
        </li>
        <!-- <li>
          <a translate [href]="'/management' + getUrl()">{{
            "link.menu.management"
          }}</a>
        </li> -->
      </ul>
    </div>

    <div class="l-header__humberge">
      <a href="/kids-experience">
        <img
          alt="子供向け|無料体験"
          src="./../../../../assets/img/new/contact-sp-icon.png"
          class="l-header__humberge__access"
      /></a>
      <a href="/access">
        <img
          alt="アクセス"
          src="./../../../../assets/img/new/access-sp-icon.png"
          class="l-header__humberge__access"
      /></a>
      <img
        *ngIf="!isHumberger"
        src="./../../../../assets/img/menu.png"
        alt="メニュー"
        (click)="onHumberger()"
        class="l-header__humberge__menu"
      />
      <img
        class="--close"
        *ngIf="isHumberger"
        src="./../../../../assets/img/close.png"
        alt="メニュー"
        (click)="onHumberger()"
        class="l-header__humberge__menu"
      />
    </div>

    <div class="l-header__panel" *ngIf="isHumberger">
      <ul>
        <li>
          <a href="/semi-personal">
            <p translate>セミパーソナル</p>
          </a>
        </li>
        <!-- <li>
          <a href="/top-kids">
            <p translate>小学生向け運動</p>
          </a>
        </li>
        <li>
          <a href="/preschooler">
            <p translate>未就学児向け運動</p>
          </a>
        </li> -->
        <li>
          <a href="/price">
            <p translate>料金プラン</p>
          </a>
        </li>
        <li>
          <a href="/schedule">
            <p translate>スケジュール</p>
          </a>
        </li>
        <li>
          <a href="/facility">
            <p translate>設備紹介</p>
          </a>
        </li>
        <li>
          <a href="/blog-list">
            <p translate>ブログ</p>
          </a>
        </li>
        <li>
          <a href="/voice">
            <p translate>お客様の声</p>
          </a>
        </li>
        <li>
          <a href="/faq">
            <p translate>よくある質問</p>
          </a>
        </li>

        <li>
          <a href="https://twitter.com/dynamo_b_studio" target="_blank">
            <img
              alt="ダイナモBスタジオのtwitter"
              src="./../../../../assets/img/twitter.png"
              width="30px"
              height="auto"
              translate
            />
          </a>
          <a href="https://www.instagram.com/dynamo_b_studio/" target="_blank">
            <img
              alt="ダイナモBスタジオのInstagram"
              src="./../../../../assets/img/instagram.png"
              width="30px"
              height="auto"
              translate
            />
          </a>
          <a href="https://lin.ee/aYwugvk">
            <img
              alt="Lineでお問い合わせ"
              src="./../../../../assets/img/new/line-contact-sp.jpg"
              width="120px"
              height="auto"
              translate
            />
          </a>
        </li>
      </ul>
    </div>
  </div>
</form>
