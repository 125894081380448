import { createClient } from 'microcms-js-sdk';
export const environment = {
  production: true,
  baseURL: 'http://localhost:3000',
  mailTo: 'imajin8280@gmail.com',
  logger: 'logger',
  biller: 'logger',
  otherCategory: 'other',
};

export const client = createClient({
  serviceDomain: 'dynamo-b-studio',
  apiKey: 'AF2v3WX5IbJV9VBNgV3gFH5PlubptChAjrPK',
});

export const puppeteerSetting = {
  headless: true,
  args: ['--no-sandbox', '--disabled-setuid-sandbox'],
};
