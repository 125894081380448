import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-customers-voice-view',
  templateUrl: './customers-voice-view.component.html',
  styleUrls: ['./customers-voice-view.component.scss'],
})
export class CustomersVoiceViewComponent implements OnInit {
  constructor() {}
  public number = 0;
  ngOnInit(): void {}

  onNumber(number: number) {
    this.number = number;
  }
}
