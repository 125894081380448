<div class="l-form">
  <label *ngIf="label"> {{ label }}</label>
  <span
    ><ng-container *ngIf="require"
      ><span class="c-require">必須</span></ng-container
    ></span
  >
  <div class="l-form__content">
    <select
      #selectbox
      (change)="onChange(selectbox.value)"
      (blur)="onTouched()"
      class="c-select-years"
    >
      <!-- <option value="" *ngIf="blank !== null">{{ blank }}</option> -->
      <option
        [value]="option"
        *ngFor="let option of options"
        [selected]="selected === option"
      >
        {{ option + lastLabel }}
      </option>
    </select>
    <div class="l-error">
      <div *ngIf="control?.invalid && (control?.dirty || control?.touched)">
        <div class="error-messege" *ngIf="control?.errors?.required">
          {{ label }}を入力してください。
        </div>
      </div>
    </div>
  </div>
</div>
